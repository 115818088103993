import React from 'react';
import axios from 'axios';
import server from '../common/config';
import history from '../common/history';
import ErrorPage from '../common/ErrorPage';
import Loading from '../common/Loading';
import JsxParser from 'react-jsx-parser';
import Navbar from '../common/Navbar';
import Breadcrump from './Breadcrumb';
import Footer from '../common/Footer';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayTrigger } from 'react-bootstrap';
import PaymentSuccess from '../common/PaymentSuccess';

class Course extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            error:false, 
            loading:true,
            paymentSuccess:false,
            courseDesc:"<p>Yükleniyor...</p>",
            courseCampagin:"0",
            coursePrice:{priceK:0,priceL:0,oldPriceK:0,oldPriceL:0,text:""},
            courseBuyed:false,
            courseLessons:[],
            showPaymentDialog:false,
            paymentButtonLoading:false,
            formFields: {campaginCode: ''},
            paymentError:"",
            reviewList: []
        };
        this.global = {
            loaded:0, 
            loadable:4,

            lastLessonName:"",
            lastSublessonName:"",
            lastLessonURL:""
        
        };
    }

    componentDidMount()
    {
        axios.get(server+"course/getDesc/"+this.props.match.params.name+".php")
        .then((res) => {
            if(res.data === 0)
            {
                this.setState({error:true});
                console.error("Kurs bulunamadı veya başka bir hata var (kurs tanımı alınırken)");
            }else{
                this.setState({courseDesc:res.data});

                this.global.loaded++;
                if(this.global.loaded >= this.global.loadable)
                    this.setState({loading:false});
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("kurs tanımı alınırken sunucu hatası");
        });

        //////////////////////////////

        axios.get(server+"course/getCampagin/"+this.props.match.params.name+".php")
        .then((res) => {
            this.setState({courseCampagin:res.data});

            this.global.loaded++;
                if(this.global.loaded >= this.global.loadable)
                    this.setState({loading:false});
        })
        .catch(() => {
            this.setState({error:true});
            console.error("kurs kampanyası alınırken hata")
        });

        //////////////////////////////

        axios.get(server+"course/getPriceInfo/"+this.props.match.params.name+".php")
        .then((res) => {
            if(res.data === 0)
            {
                console.error("Fiyat bilgisi alınırken hata");
                this.setState({error:true});
            }else{
                this.setState({coursePrice:res.data});

                this.global.loaded++;
                if(this.global.loaded >= this.global.loadable)
                    this.setState({loading:false});

            }
        })
        .catch(() => {
            console.error("Fiyat bilgisi alınırken sunucu hatası");
            this.setState({error:true});
        });

        //////////////////////////////

        if(localStorage.session !== undefined && localStorage.session !== "undefined")
        {
            const data = {token:localStorage.session, course:this.props.match.params.name};
            axios.post(server+"course/checkIfUserBuyedCourse.php", data)
            .then((res) => {
                this.global.loaded++;
                if(res.data === 1)
                    this.global.loadable++;
                if(this.global.loaded >= this.global.loadable)
                    this.setState({loading:false});
                if(res.data === 0) 
                {
                    this.setState({courseBuyed:false});

                }
                else if(res.data === 1) 
                {
                    this.setState({courseBuyed:true});
                    let temp = {token: localStorage.session};
                    axios.post(server+"course/getLessonList/"+this.props.match.params.name+".php",temp)
                    .then((res) => {
                        if(res.data === 0)
                        {
                            this.setState({error:true});
                            console.error("Ders listesini çekerken hata");
                        }else{
                            this.setState({courseLessons:res.data});
                            console.log("DEBUG ONLY");
                            console.log(res.data);
                            this.global.loaded++;
                            if(this.global.loaded >= this.global.loadable)
                                this.setState({loading:false});
                        }
                    })
                    .catch(() => {
                        this.setState({error:true});
                        console.error("Ders listesini çekerken sunucu hatası");
                    });
                }
            })
            .catch(() => {
                this.setState({error:true});
                console.error("Kursun satın alınıp alınmadığı denetlenirken sunucu hatası");
            });

            axios.post(server+"course/getReviewList.php",data)
            .then((res) => {
                this.setState({reviewList:res.data});
            })
            .catch(() => {
                console.error("Yeniden gözden geçirme listesini çekerken ağ hatası!");
            });
        }



    }

    showPaymentDialog(){
        this.setState({showPaymentDialog:true});
    }
    hidePaymentDialog(){
        this.setState({showPaymentDialog:false});
    }

    handlePayment(item)
    {
        item.target.disabled = true;
        this.setState({paymentButtonLoading:true});
        //this.state.formFields.campaginCode
        let data ={token:localStorage.session, campaginCode:this.state.formFields.campaginCode}
        axios.post(server+"course/buyCourse/"+this.props.match.params.name+".php", data)
        .then((res) => {
            if(res.data === 1)
            {
                //hata yok
                this.setState({paymentSuccess:true})
            }else if(res.data === -1){
                //geçersiz kampanya kodu
                this.setState({paymentButtonLoading:false});
                item.target.disabled = false;
                this.setState({paymentError:"Girdiğiniz kampanya kodu bulunamadı, zamanaşımına uğramış veya kullanım limiti dolmuş."});
            }else if(res.data === -2){
                this.setState({paymentButtonLoading:false});
                item.target.disabled = false;
                this.setState({paymentError:"Bu kursu zaten daha önce satın almışsınız. Kullanmaya başlayabilirsiniz."});
            }else if(res.data === -3){
                this.setState({paymentButtonLoading:false});
                item.target.disabled = false;
                this.setState({paymentError:"Lütfen gerekli alanları doldurun."});
            }else{
                //diğer hata
                this.setState({error:true});
                console.error("Ödeme yapılırken teknik hata!");
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("Ödeme yapılırken ağ hatası!");
        });

    }

    handleFormChange(item)
    {
        let data = {formFields:{campaginCode:item.target.value}};
        if(this.state.paymentError !== "")
            data.paymentError = "";
        this.setState(data);
    }

    render(){
        const renderTooltip = (props) => (
            <Tooltip className="user-select-none" {...props}>
              Burada listelenen içerikler, ilgili içeriğin sorularında belirli bir miktardan daha fazla hata yaptığınızda gösterilir.<br/>
              Soruları doğru çözdüğünüzde bu içerikler bu listeden kaldırılır.
            </Tooltip>
        );


        if(this.state.error)
            return(<ErrorPage/>);
        else if(this.state.loading)
            return(<Loading/>);
        else if(this.state.paymentSuccess){
            return(<PaymentSuccess/>);
        } else if(this.state.courseBuyed)
        {
            return(<>
            <Navbar/>
            <div className = "container" style={{marginBottom:"150px"}}><br/>
                <div className="course overflow-auto">
                    <Accordion>
                    {
                        this.state.courseLessons.map((lesson,i) => {
                            return(
                                <Card className="lesson" key={i}>
                                    <Accordion.Toggle as={Card.Header} eventKey={i.toString()}>
                                        <b>{lesson.name}</b>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={i.toString()}>
                                        <Card.Body>
                                            {
                                                lesson.sublessons.map((sublesson,q) => {
                                                    if(sublesson.last)
                                                    {
                                                        this.global.lastLessonURL = process.env.PUBLIC_URL+"/ders/"+this.props.match.params.name+"/"+lesson.url+"/"+sublesson.url;
                                                        this.global.lastSublessonName = sublesson.name;
                                                        this.global.lastLessonName = lesson.name;
                                                    }
                                                    if(sublesson.type === 1) //bu bir ders
                                                        return(<><Button key={" c"+i+"l"+q} variant={sublesson.last ? "warning" : "success"} onClick={() => history.push(process.env.PUBLIC_URL+"/ders/"+this.props.match.params.name+"/"+lesson.url+"/"+sublesson.url)} disabled={!sublesson.complated}>{sublesson.name}</Button><br/></>);
                                                    else if(sublesson.type === 2) //bu bir sınav
                                                        return(<><Button key={"s-c"+i+"l"+q} variant={sublesson.last ? "warning" : "info"} onClick={() => history.push(process.env.PUBLIC_URL+"/kurslar/quizPlayer/"+sublesson.url)} disabled={!sublesson.complated}>{sublesson.name}</Button><br/></>);

                                                })
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        })
                    }
                    </Accordion>

                    <center>
                        <Button variant='primary' style={{marginTop:"15px"}} onClick={() => history.push(this.global.lastLessonURL)}>
                            Kaldığım Yerden ({this.global.lastLessonName + " -> " + this.global.lastSublessonName}) Devam Et
                        </Button>
                    </center>
                </div>
                <p style={{marginTop:"20px", marginBottom:"0px"}}>Bu konuları tekrar gözden geçirmek isteyebileceğinizi düşündük. 
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <i className="bi bi-question-circle-fill" style={{color:"yellow", paddingLeft:"10px"}}></i>
                </OverlayTrigger>
                </p>
                <div className="course overflow-auto">
                    {
                        this.state.reviewList.length == 0 ?
                            <Alert variant='success'>Sorularını belirlenen değerden daha fazla yanlış yaptığınız içerik bulunamadı.</Alert>
                        :
                        this.state.reviewList.map((review, i) => {
                            return <div key={"rev"+i} className="reviewLesson" onClick={() => history.push(process.env.PUBLIC_URL+"/ders/"+this.props.match.params.name+"/"+review.url)} style={{cursor:"pointer"}}>{review.name}</div>;
                        })
                    }
                </div>
            </div>
            <Footer/>
            </>);

        }else return(
        <div className="user-select-none">
            <div className="container">
                <Navbar/>
                <Alert variant='warning'>
                    <h3>Aktivasyon kodunuz mu var?</h3>
                    <hr style={{borderColor:"#3d3622"}} />
                    <p>TürkiyeElektronik'in düzenlediği bir çekilişten aktivasyon kodu kazandıysanız bu kodu aşağıdaki forma yazarak hesabınıza tanımlayabilirsiniz.</p>
                    <Form>
                        <Form.Group>
                            <Form.Label>Aktivasyon Kodu</Form.Label>
                            <Form.Control type="text" value={this.state.formFields.campaginCode} placeholder="Aktivasyon kodunuzu giriniz."  onChange={(item) => this.handleFormChange(item)}/>
                        </Form.Group>
                    </Form>
            {this.state.paymentError == "" ? "" : <Alert variant="danger">{this.state.paymentError}</Alert>}
            <Button variant="success" onClick={(item) => this.handlePayment(item)}>
                {this.state.paymentButtonLoading ? <Spinner animation="grow" variant="primary" visible="false" /> : "İşlemi Tamamla"}
            </Button>
                </Alert>

                
            </div>
            <Footer/>

            <Modal className="user-select-none" style={{color:'black'}} show={this.state.showPaymentDialog} onHide={() => this.hidePaymentDialog()}>
        <Modal.Header closeButton>
          <Modal.Title>Kursu Satın  Al</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p style={{color:"red"}}>Bu işlemi onayladığınızda herhangi bir kampanyaya dahil olmazsınız ve herhangi bir satış işlemi gerçekleşmez. 
            "Kampanya Kodu" içeriklere erişim yetkinizi denetlemek için kullanılır. Ayrıntılar için bu penceyi kapatıp sayfadaki bilgilendirme metnini okuyunuz.</p>
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.hidePaymentDialog()}>
            Kapat
          </Button>
          
        </Modal.Footer>
      </Modal>
        </div>
        );
    }
}

export default Course;