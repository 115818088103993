import React from 'react';

class Breadcrump extends React.Component {
    render(){
        return(
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-dark">
                    <li className="breadcrumb-item"><a href="#">TürkiyeElektronik Ana Sayfası</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Kurslar</li>
                </ol>
            </nav>
        );
    }
}

export default Breadcrump;