import '../App.css';
import history from './history';
import Button from 'react-bootstrap/Button';

function PaymentSuccess() {
  return (
    <>
    <div className="errorPageMain">
      <svg xmlns="http://www.w3.org/2000/svg" width="20%" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </svg>
      <h1>Tebrikler!</h1>
      <p><b>Girdiğiniz kod kayıtlarımızla eşleşti ve bu içeriğe erişim hakkınız doğrulandı.</b></p>
        <div style={{margin:"auto", width:"fit-content"}}>
          <Button variant="success" onClick={() => window.location.reload()}>Haydi İlk Derse Gidelim</Button>
        </div>
    </div>
    </>
  );
}

export default PaymentSuccess;
