import React from 'react';
import axios from 'axios';
import JsxParser from 'react-jsx-parser';
import server from '../common/config';
import Loading from '../common/Loading';
import ErrorPage from '../common/ErrorPage';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import ProgressBar from 'react-bootstrap/ProgressBar';
class ProjectPlayer extends React.Component
{
    constructor(props)
    {
        super(props);
        this.global = {
            loaded:0,
            loadable:1
        };

        this.state = {
            error:false,
            loading:true,
            description:"",
            selectedFiles:undefined,
            progress:0,
            message:"Henüz bir dosya seçilmedi."
        };

        this.handleUpload = this.handleUpload.bind(this);
        this.handleUploadButton = this.handleUploadButton.bind(this);
    }

    componentDidMount()
    {
        let data = {token:window.localStorage.session, pid:this.props.match.params.pid};
        axios.post(server+"course/getProjectDescription.php",data)
        .then((res) => {
            if(res.data === 0)
            {
                console.error("Proje açıklaması çekilirken hata");
                this.setState({error:true});
            }else{
                this.setState({description:res.data});
            }
            this.global.loaded++;
            if(this.global.loaded >= this.global.loadable)
                this.setState({loading:false});
        })
        .catch(() => {
            console.error("Proje açıklaması çekilirken ağ hatası");
            this.setState({error:true});
        });
    }

    upload(file, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("pid", this.props.match.params.pid);
        formData.append("userToken", window.localStorage.session);

        return axios.post(server+"course/uploadProject.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        });
    }

    handleUpload(event)
    {
        this.setState({selectedFiles:event.target.files, message:"Yükleme işleminin başlatılması bekleniyor."});
    }

    handleUploadButton(event)
    {
        this.setState({message:"Yükleniyor..."});
        let currentFile = this.state.selectedFiles[0];
        this.upload(currentFile, (e) => {
            this.setState({progress:Math.round((100 * e.loaded) / e.total)});
        })
        .then((res) => {
            if(res.data !== 1)
            {
                this.setState({message:"Dosya yüklenirken bir hata oluştu. Boyut sınırı aşılmış olabilir veya desteklenmeyen bir dosya türü yüklemeyi denediniz. Algılanan hata mesajı: "+res.data});
            }else{
                this.setState({message:"Dosya başarıyla yüklendi."});
            }
        })
        .catch(() => {
            console.error("Dosya yükleme denemesi ağ hatası ile sonuçlandı!");
            this.setState({error:true});
        });


    }

    render(){
        if(this.state.error)
            return(<ErrorPage/>);
        else if(this.state.loading)
            return(<Loading/>);
        else
        return(<>
        <Navbar />
        <div className="container">
            <div className="kursBaslik"><span className="kursAdi">Proje Görevi</span> - <span className="konuAdi">deneme</span><hr/></div>
            <h2 style={{textDecoration:"underline", paddingTop:"30px"}}>Tanım</h2>
            <JsxParser jsx={this.state.description}/>
            <h2 style={{textDecoration:"underline", paddingTop:"30px"}}>Dosyayı Yükleyin</h2>
            <p>Aşağıdaki alandan projenizi bulunduran dosyayı gönderiniz. <i>Yalnızca Python Dosyaları <b>(*.py)</b> geçerlidir. 
            Dosyalar manuel olarak incelenecektir, dolayısıyla içerisinde kişisel bilgilerinizin bulunduğu dosyaları sistemimize 
            yüklemeyiniz.</i></p>
            <center>
                <div className="col-md-6" style={{border:"1px solid white", borderRadius:"15px", padding:"10px"}}>
                    <label className="btn btn-default" style={{color:'white', border:"1px solid white"}}>
                        <input type="file" onChange={this.handleUpload} />
                    </label>
                    <button className="btn btn-success" style={{marginLeft:"20px"}} onClick={this.handleUploadButton}>Yükle</button>
                    <ProgressBar animated now={this.state.progress} label={"%"+this.state.progress.toString()} />
                    <div className="alert alert-warning" role="alert" style={{marginTop:"10px"}}> <JsxParser jsx={this.state.message} /></div>
                </div>
            </center>

        </div>
        <Footer/>
        </>);
    }
}

export default ProjectPlayer;