import React from 'react';
import axios from 'axios';
import history from './history';
import JsxParser from 'react-jsx-parser';
import server from './config';
import ErrorPage from './ErrorPage';
import Navbar from './Navbar';
import Footer from './Footer';
import Alert from 'react-bootstrap/Alert';

class JsxComponents extends React.Component
{
    static defaultProps = {
        content: "",
        type: "",
        linkTo: ""
    };
    render(){
        if(this.props.type === "p")
        {
            if(this.props.linkTo !== "")
                return(<p onClick={() => history.push(this.props.linkTo)}>{this.props.content}</p>);
            else
                return(<p>{this.props.content}</p>);
        }else if(this.props.type === "h1")
        {
            if(this.props.linkTo !== "")
                return(<h1 onClick={() => history.push(this.props.linkTo)}>{this.props.content}</h1>);
            else
                return(<h1>{this.props.content}</h1>);
        }else if(this.props.type === "h2")
        {
            if(this.props.linkTo !== "")
                return(<h2 onClick={() => history.push(this.props.linkTo)}>{this.props.content}</h2>);
            else
                return(<h2>{this.props.content}</h2>);
        }
        return(<></>);
    }
}

class Page extends React.Component
{
    contentJsx = "Yükleniyor...";

    static defaultProps = {
        pageName: ""
    };

    constructor(props)
    {
        super(props);
        this.state = {pageName:"", blocks:{}, html:"<p>Yükleniyor...</p>", error:false};
    }

    componentDidMount() {
        let pageName = this.props.pageName;
        if(this.props.match !== undefined && this.props.match !== "undefined")
            pageName = this.props.match.params.pageName;
        this.setState({pageName:pageName});
        const data = {page:pageName};

        axios.post(server+"page/getContent.php",data)
        .then((res) => {
            if(res.data === 0 || res.data === 4041 || res.data === 4042)
            {
                //hata
                console.error("Sayfa bulunamadı veya erişim izni yok ("+pageName+")");
                this.setState({error:true});
            }else{
                //sayfada hata yok, sayfayı oluştur.
                this.setState({html:res.data.html});
                /*this.contentJsx = "";
                Object.keys(res.data.blocks).forEach(key => {
                    let addons = "";
                    if(res.data.blocks[key].linkTo !== undefined && res.data.blocks[key].linkTo !== "undefined")
                    addons += 'linkTo="'+res.data.blocks[key].linkTo+'" ';
                        
                    this.contentJsx += '<JsxComponents type="'+res.data.blocks[key].type+'" '+addons+' content="'+res.data.blocks[key].content+'"/>';

                })
                this.forceUpdate();*/
                //this.setState({blocks:res.data.blocks});
            }
        })
        .catch(() => {
            console.error("sayfa verileri çekilirken sunucu hatası");
            this.setState({error:true});
        });
    }

    render()
    {
        if(this.state.error) return(<ErrorPage />);
        else return(
        <div>
            <Navbar/>
            <div className="container">
            <Alert variant="warning">İncelemekte olduğunuz bu sayfa son şeklini almamıştır ve üretim sürümünde kaldırılacak, eklenecek veya değiştirilecek bölümler olabilir.</Alert>
                <JsxParser jsx={this.state.html} />
            </div>
            <Footer/>
        </div>
        );
    }
}
//<JsxParser components={{JsxComponents}} jsx={this.contentJsx} />
export default Page;