import "./home.css";
import React from 'react';
import axios from 'axios';
import server from './config';
import JsxParser from "react-jsx-parser";
import Navbar from './Navbar';
import Footer from './Footer';
import history from './history';
import ErrorPage from './ErrorPage';
import { Modal } from "react-bootstrap";
import Page from './Page';

import Button from 'react-bootstrap/Button';
import Loading from '../common/Loading';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

class Home extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            error:false, 
            logined:false, 
            userName:"", 
            loading:true,
            pageData:{page1_1:'', page1_2:'', page2_1:'', page2_2:'', page3_1:'', page4_1:''},
            info:false,
            infoType:true,
            infoMessage:""
        };

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if(params.email == "success")
        {
            //e-posta başarıyla onaylandı.
            this.state.info = true;
            this.state.infoType = true;
            this.state.infoMessage = "E-posta adresiniz başarıyla onaylandı.";

            //this.state = {... {info:true, infoType:true, infoMessage:""}};
        }else if(params.email == "error")
        {
            //e-posta onaylanırken sorun oluştu.
            this.state.info = true;
            this.state.infoType = false;
            this.state.infoMessage = "E-posta adresiniz onaylanırken bir hata oluştu veya e-posta adresiniz zaten onaylanmış. Bizimle destek@turkiyeelektronik.com adresinden iletişime geçebilirsiniz.";
        }else if(params.phone == "success"){
            //telefon numarası başarıyla onaylandı.
            this.state.info = true;
            this.state.infoType = true;
            this.state.infoMessage = "Cep telefonu numaranız başarıyla onaylandı.";
        }else if(params.phone == "error")
        {
            //telefon numarası onaylanırken sorun oluştu.
            this.state.info = true;
            this.state.infoType = false;
            this.state.infoMessage = "Cep telefonu numaranız onaylanırken bir hata oluştu veya numaranız zaten onaylanmış. Bizimle destek@turkiyeelektronik.com adresinden iletişime geçebilirsiniz.";
        }

        //suncu bağlanılabilirliğini kontrol et
        axios.get(server+"status/server.php")
        .then((res) => {
            if(res.data === 1)
            {
                //hata yok
                //kullanıcı giriş yapmış mı?
                let data = {token:localStorage.session};
                axios.post(server+"user/checkLogin.php", data)
                .then((res) => {
                    if(res.data === 1)
                    {
                        //kullanıcı giriş yapmış.
                        this.setState({logined:true});
                        //kullancı adını çekelim
                        data.field = "name";
                        axios.post(server+"user/getContent.php",data)
                        .then((res) => {
                            if(res.data === 0)
                            {
                                console.error("kullanıcı adını çekerken hata");
                                this.setState({error:true, loading:false});
                            }else{
                                //kullancı adı başarıyla çekildi
                                this.setState({userName:res.data, loading:false});
                            }
                            
                        })
                        .catch(() => {
                            //hata
                            console.error("Kullancı adı çekilirken sunucu hatası oluştu.");
                            this.setState({error:true});
                        });

                    }else{
                        //kullanıcı giriş yapmamış, ana sayfa verisini çek
                        axios.get(server+"preRegister/getHomePageData.php")
                        .then((res) => {
                            if(res.data.code === 100)
                            {
                                const dta = {page1_1:'', page1_2:'', page2_1:'', page2_2:'', page3_1:'', page4_1:''};
                                res.data.data.forEach(element => {
                                    dta[element.name] = element.content;
                                });
                                this.setState({loading:false, pageData:dta});
                            }else{
                                this.setState({loading:false});
                                console.error("Sayfa verisi çözümlenemedi!");
                                console.log(res.data);
                            }
                        })
                        .catch((e) => {
                            alert("Sayfa yüklenirken bir hata oluştu: " + e.toString());
                            console.error(e);
                            this.setState({loading:false});
                        });
                    }
                })
                .catch(() => {
                    //kullanıcının giriş yapıp yapmadığını kontrol ederken sunucu hatası
                    console.error("kullanıcının giriş yapıp yapmadığını kontrol ederken hata");
                    this.setState({error:true});
                });
            }else{
                //suncu istek kabul etmiyor.
                console.error("sunucu istek kabul etmiyor.");
                this.setState({error:true, loading:false});
            }
        })
        .catch(() => {
            //suncu hatası
            console.error("sunucu durumu kontrol edilirken suncu hatası");
            this.setState({error:true});
        });

        this.refRegister = React.createRef();
    }

    render()
    {
        const {userName} = this.state;
        if(this.state.error)
            return(<ErrorPage />);
        else if(this.state.loading)
            return(<Loading/>);
        else if(this.state.logined)
        {
            <Modal show={this.state.info} onHide={() => this.setState({info:false})} centered style={{color:"black"}}>
                <Modal.Header closeButton>
                    <Modal.Title>İşlem Sonucu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{this.state.infoType ? "Başarılı!" : "Bir hata oluştu!"}</h4>
                    <Alert variant={this.state.infoType ? "success" : "danger"}><JsxParser jsx={this.state.infoMessage ? this.state.infoMessage : "Detaylı bilgi mevcut değil."} /></Alert>
                </Modal.Body>
            </Modal>

            //giriş yapmış bir kullanıcı
            return(
                <>
                <Navbar />
                <div className="container">
                <p>Hoş geldiniz{userName !== "" ? " sayın "+userName:""}!</p>

                <div className="row">
                    <div className="col-md-4">
                <Card>
                    <Card.Header>Kurslar</Card.Header>
                    <Card.Body>
                        <Card.Title>TürkiyeElektronik Kursları</Card.Title>
                        <Card.Text>
                        Yeni hizmete giren kurs sistemimize aşağıdaki düğmeye tıklayarak ulaşabilirsiniz.
                        </Card.Text>
                        <Button variant="success" onClick={() => history.push("/kurslar")}>Kurslara Gözat</Button>
                    </Card.Body>
                </Card>
                </div>
                </div>

                </div>
                <Footer/>
                </>
            );

            /**
             * 
             <div className="col-md-4">
                <Card>
                    <Card.Header>Çekilişler</Card.Header>
                    <Card.Body>
                        <Card.Title>TürkiyeElektronik Çekilişleri</Card.Title>
                        <Card.Text>
                        TürkiyeElektronik çekilişlerine aşağıdaki bağlantılarla katılabilirsiniz.
                        </Card.Text>
                        <Button variant="success" onClick={() => history.push("/cekilisler")}>Çekilişlere Gözat</Button>
                    </Card.Body>
                </Card>
                </div>

                <div className="col-md-4">
                <Card>
                    <Card.Header>İndirimler</Card.Header>
                    <Card.Body>
                        <Card.Title>TürkiyeElektronik İndirimleri</Card.Title>
                        <Card.Text>
                        TürkiyeElektronik ürünlerindeki indirim fırsatlarını aşağıda bulabilirsiniz.
                        </Card.Text>
                        <Button variant="success" onClick={() => history.push("/kurslar")}>Kurslara Gözat</Button>
                    </Card.Body>
                </Card>
                </div>
             */
        }else{
            //giriş yapmamış bir kullanıcı
            if(this.state.error)
            return(<ErrorPage />);
        else
            return(
            <>
            <Modal show={this.state.info} onHide={() => this.setState({info:false})} centered style={{color:"black"}}>
                <Modal.Header closeButton>
                    <Modal.Title>İşlem Sonucu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{this.state.infoType ? "Başarılı!" : "Bir hata oluştu!"}</h4>
                    <Alert variant={this.state.infoType ? "success" : "danger"}><JsxParser jsx={this.state.infoMessage ? this.state.infoMessage : "Detaylı bilgi mevcut değil."} /></Alert>
                </Modal.Body>
            </Modal>

                <div>
                    <Navbar/>
                    <div className="container">
                        <div className="page center">
                            <img width="150px" src="https://images.turkiyeelektronik.com/logoCompressedSmall.png" alt="TürkiyeElektronik Logosu"></img>
                            <JsxParser jsx={this.state.pageData.page1_1} />
                            <p className="colorA"><JsxParser jsx={this.state.pageData.page1_2} /></p>
                            <button className="preRegister" onClick={() => this.refRegister.current.scrollIntoView({behavior: 'smooth'})}>Ön Kayıt Yap</button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="#99c2ff" className="bi bi-chevron-bar-down animated bounce" viewBox="0 0 16 16" style={{marginTop:"1%"}}>
                                <path fillRule="evenodd" d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            <img style={{maxWidth:"40%", minWidth:"250px"}} alt="TürkiyeElektronik Üye Sayısı 500'ü Aştı!" src="https://images.turkiyeelektronik.com/trePost6Compressed.png" />
                        </div>

                        <center>
                            <div style={{width: "80%", height: "80vh"}} className="center">

                                <p style={{marginBottom:"0", marginTop:"20px"}}><b>Çekiliş Videomuz:</b></p>
                                <iframe 
                                    src="https://iframe.mediadelivery.net/embed/24499/e9f8a740-bce1-443c-a068-8e9059b583c5?autoplay=false&preload=false" 
                                    loading="lazy" 
                                    style={{border: "none",height:"50vh", width: "100%"}} 
                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
                                    allowFullScreen="true"
                                >
                                </iframe>

                                <p style={{marginBottom:"0", marginTop:"20px"}}><b>Sistemimizin Kullanımı:</b></p>
                                <iframe 
                                    src="https://iframe.mediadelivery.net/embed/24499/39b876da-7507-4cb0-91c0-0d669fb64c55?autoplay=false&preload=false" 
                                    loading="lazy" 
                                    style={{border: "none",height:"50vh", width: "100%", marginBottom:"50px"}} 
                                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
                                    allowfullscreen="true"
                                >
                                </iframe>
                            </div>
                        </center>
                        <div className="page colorBgA center">
                            <h1>Nasıl Çalışıyor?</h1>
                            <p style={{marginBottom:"0"}}><JsxParser jsx={this.state.pageData.page2_1} /></p>
                            <img style={{width:'170px', filter:"invert(55%)"}} alt="" src="https://images.turkiyeelektronik.com/course/imageRepo/web-1873373.svg"/>
                            <JsxParser jsx={this.state.pageData.page2_2} />
                        </div>

                        <div className="page center colorBgB">
                            <h1 style={{color:"white"}}>Sürekli İyileşme Sistemi.</h1>
                            <JsxParser jsx={this.state.pageData.page3_1} />
                        </div>
                        <div ref={this.refRegister} className="page center colorBgC" style={{marginBottom:"150px"}}>
                            <h1 style={{color:"white"}}>Ön Kayıt</h1>
                            <JsxParser jsx={this.state.pageData.page4_1} />
                            <div className="preRegister2 btn btn-success" onClick={() => history.push("/giris")} style={{marginBottom:'15px', cursor:'pointer'}}>Şimdi Kaydol</div>        
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
            );
            //return(<Page pageName="home" />);
        /**
        return(
            <>
            <Navbar />
            <div className="container">
                <Jumbotron className="bg-dark">
                    <h1>TürkiyeElektronik'e Hoş Geldiniz!</h1>
                    <p>
                        TürkiyeElektronik'te kendinizi geliştirebileceğiniz kurslar bulabilirsiniz.
                    </p>
                    <p>
                        <Button variant="primary">Deneme Kurslarına Bakalım</Button>
                    </p>
                </Jumbotron>
            </div>

            <div className="container">
                <Jumbotron className="bg-dark">
                    <h1>Çekiliş</h1>
                    <p>
                        Kuruluşumuza özel olarak hazırladığımız çekilişimiz ile kurslarımıza ücretsiz erişebilecek 20 şanslı kişiden
                        biri olabilirsiniz. Çekilişe katılmak için üye olmanız gerekmektedir. <a>Çekiliş hakkında daha falza bilgi için tıklayın.</a>
                    </p>
                    <p>
                        <Button variant="primary" onClick={() => history.push("/giris")}>Üye Ol</Button>
                    </p>
                </Jumbotron>
            </div>
            <Footer />
            </>
        );
        */
        }
    }
}

export default Home;