import React from 'react';
import axios from 'axios';
import sha256 from 'js-sha256';
import server from './config';
import history from './history';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
//import {showLoader, hideLoader} from '../loading';
import Navbar from './Navbar';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ErrorPage from './ErrorPage';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';
class Login extends React.Component {
    constructor(props)
    {
      console.log("component loaded - login");
      super(props);
      this.state = {
        usernameL:"", 
        passwordL:"", 
        rmMe:false, 
        loginButtonLoading: false,
        loading: true,
        error: false,
        showPasswordReset: false,
        emailForRP: "",
        asama:1,
        vercode:"",
        newPassword:"",
        kayitHata: "",
        kayitBasarilli:"",
		
		kSozlesme:false,
		kParola1:"",
		kParola2:"",
		kAd:"",
		kTelno:"",
		kEposta:"",
    kTei:false
      };


      this.handleButton = this.handleButton.bind(this);
      this.handleChange = this.handleChange.bind(this);

      this.kRef = React.createRef();
      
      this.setState({ loading: true });
      axios.get(server + "status/server.php")
      .then((res) => {
        if(res.data === 1)
        {
          //sunucu istek kabul ediyor.
          
          console.log("server accepts requests");
        }else{
          //sunucu istek kabul etmiyor.
          this.giveError("server is not accepting requests");
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        //sunucunun istek kabul edip etmediğini öğrenirken sunucu hatası.
        this.setState({ loading: false });
        this.giveError("server error (while checking server status)");
      });
    }
  
    componentDidMount() {
      // this simulates an async action, after which the component will render the content
      this.setState({ loading: false });
    }

    giveError(info = "")
    {
      this.setState({error:true});
      if(info !== "") console.error(info);
    }

    handleButton(button)
    {
      if(button === 0)
      {
        //giriş yap
        this.setState({loginButtonLoading:true});
        if(this.state.usernameL === "" || this.state.passwordL === "")
        {
          //Boş alanlar mevcut
          //TODO: Toast message
          alert("lütfen tüm alanları doldurun.");
          this.setState({loginButtonLoading:false});
        }else{
          //form verisi geçerli.
          //bir token rezerve edelim.
          axios.get(server +'tokenManager/create.php?for=login')
          .then((res) => {
            //cevap döndü
            localStorage.session = res.data;
            //var data = '{"token":"'+res.data+'", "email":"'+this.state.usernameL+'", "password":"'+sha256(this.state.passwordL)+'", "rememberMe":'+this.state.rmMe+'}';
            const data = {
              token: res.data,
              email: this.state.usernameL,
              password: this.state.passwordL, //sha256(this.state.passwordL),
              rememberMe: this.state.rmMe
            }

            axios.post(server+'user/login.php', data)
            .then((res) => {
              if(res.data === 1)
              {
                //başarıyla giriş yapıldı.
                //redirect
                if(sessionStorage.redirectTo !== undefined && sessionStorage.redirectTo !== "undefined")
                { 
                  history.push(sessionStorage.redirectTo);
                  sessionStorage.removeItem("redirectTo");
                }
                else
                  history.push("/");
              }else if(res.data === 0){
                //giriş hatası, bilgilerinizi kontrol ediniz.
                alert("bilgilerinizi kontrol ediniz");
                this.setState({loginButtonLoading:false});
              }else if(res.data === -1){
                //e-posta aktivasyonu gerekiyor.
              }else{
                //sunucu bilinmeyen veri döndürdü.
                this.giveError("server returned bad value while login process.");
              }
            })
            .catch(() => {
              this.giveError("server error (while sending login request)");
            });
          })
          .catch(() => {
            //hata oluştu
            this.giveError("server error (while requesting a token)");
          });
        }
        
      }else if(button === 1)
      {
        //google ile giriş
        alert("google ile giriş");
      }else if(button === 2){
		  //google ile kayıt
		  alert("google ile kayıt");
	  }else if(button === 3)
	  {
		  //kayıt ol
      this.handleRegister();

      /*
		  //boş alan var mı?
		  if(this.state.kEposta === "" || this.state.kAd === "" || this.state.kTelno === "" || this.state.kParola1 === "" || this.state.kParola2 === "")
		  {
			  alert("lütfen boş alan bırakmayınız.");
			  return;
		  }
		  
		  //TODO: Bu e posta adresi daha önce kaydedildi mi?
		  
		  //parolalar uyumlu mu
		  if(this.state.kParola1 !== this.state.kParola2)
		  {
			  //parolalar farklı
			  alert("girdiğiniz iki parola birbirinden farklı");
			  return;
		  }
		  
		  //sözleşme onaylandı mı?
		  if(!this.state.kSozlesme)
		  {
			  //sözleşme onaylanmadı.
			  alert("üye olabilmek için hizmet şartlarımızı kabul etmeniz gerekmektedir.");
			  return;
		  }
		  
		  //kayıt olma fonksiyonu
		  const data = {
        kEposta: this.state.kEposta,
        kTelno: this.state.kTelno,
        kParola: this.state.kParola1, //sha256(this.state.kParola1),
        kAd: this.state.kAd,
        kSozlesme: this.state.kSozlesme
      };

      axios.post(server + "user/register.php", data)
      .then((res) => {
        //suncu hatası oluşmadı.
        if(res.data === 1)
        {
          //kayıt işlemi başarılı
          alert("Başarıyla kayıt oldunuz. E-posta adresinize gönderdiğimiz bağlantı ile hesabınızı aktif hale getirebilirsiniz.");
          history.push("/");
        }else{
          //kayıt işlemi sırasında hata oluştu.
          alert("Kayıt işlemi sırasında hata oluştu. Bilgilerinizi kontrol ediniz.");
        }
      })
      .catch(() => {
        //kayıt olurken suncu hatası
        this.giveError("Kayıt işlemi sırasında suncu hatası oluştu.");
      });
      */
	  }
    }

    handleChange(event, field)
    {
      if(field === 0)
      {
        //giriş kullanıcı adı
        this.setState({usernameL: event.target.value});
      }else if(field === 1)
      {
        //giriş şifre
        this.setState({passwordL: event.target.value});
      }else if(field === 2)
      {
        //giriş beni hatırla butonu
        this.setState({rmMe: event.target.checked});
      }else if(field === 3){
		  //kayıt sözleşme butonu
		  this.setState({kSozlesme: event.target.checked});
	  }else if(field === 4)
	  {
		  //kayıt parola (tekrar)
		  this.setState({kParola2: event.target.value});
	  }else if(field === 5)
	  {
		  //kayıt parola
		  this.setState({kParola1: event.target.value});
	  }else if(field === 6)
	  {
		  //kayıt telno
		  this.setState({kTelno:event.target.value});
	  }else if(field === 7)
	  {
		  //kayıt ad
		  this.setState({kAd:event.target.value});
	  }else if(field === 8)
	  {
		  //kayıt eposta
		  this.setState({kEposta:event.target.value});
	  }else if(field === 9)
    {
      //kayıt ticari elektronik ileti
      this.setState({kTei:event.target.checked});
    }
    }

    /**
     * 
     <div className="loginWithGoogle">
						<p style={{textAlign: 'center'}}>VEYA</p><hr/>
						<Button className="form-control" variant="warning" onClick={() => this.handleButton(2)}>
							<i className="bi bi-google"></i>
							Google ile Kayıt Ol
						</Button>
					</div>
     */


          handleResetPasswordS1()
          {
            this.setState({loginButtonLoading:true});
            let fd = new FormData();
            fd.append("email", this.state.emailForRP);
            axios.post(server+"user/resetPasword.php", fd)
            .then((res) => {
              this.setState({loginButtonLoading:false});
              if(res.data != 301 && res.data != "")
              {
                if(res.data == 201)
                {
                  alert("Üye bulunamadı.");
                  return 0;
                }else if(res.data == 202)
                {
                  alert("Mesaj gönderilirken hata oluştu.");
                  return 0;
                }else{
                  localStorage.setItem("rpToken", res.data);
                  this.setState({asama:2});
                }
              }else alert("Bir hata oluştu.");
            })
            .catch((e) => {
              alert("İşleminiz sırasında bir hata oluştu: " + e.toString());
            });
          }

          handleResetPasswordS2()
          {
            this.setState({loginButtonLoading:true});
            let fd = new FormData();
            fd.append("token", localStorage.rpToken);
            fd.append("smscode", this.state.vercode);
            axios.post(server+"user/resetPasword.php", fd)
            .then((res) => {
              this.setState({loginButtonLoading:false});
              if(res.data == 1)
              {
                //başarıyla onaylandı.
                this.setState({asama:3});
              }else if(res.data == 0)
              {
                alert("Lütfen doğrulama kodunu kontrol edin.");
              }else{
                alert("Teknik hata! destek@turkiyeelektronik.com adresine bir elektronik posta göndererek durumu bize bildirebilirsiniz.")
              }
            })
            .catch((e) => {
              alert("İşleminiz sırasında bir hata oluştu: " + e.toString());
            });
          }

          handleResetPasswordS3()
          {
            if(this.state.newPassword.length < 6)
            {
              alert("Lütfen en az 6 karakter uzunluğunda bir parola giriniz. Daha uzun bir parola tercih etmeniz önerilir.");
              return 0;
            }

            this.setState({loginButtonLoading:true});
            let fd = new FormData();
            fd.append("token", localStorage.rpToken);
            fd.append("smscode", this.state.vercode);
            fd.append("newPassword", this.state.newPassword);
            axios.post(server+"user/resetPasword.php", fd)
            .then((res) => {
              this.setState({loginButtonLoading:false});
              if(res.data == 1)
              {
                //başarıyla güncellendi.
                this.setState({asama:4});
              }else{
                alert("Teknik hata! destek@turkiyeelektronik.com adresine bir elektronik posta göndererek durumu bize bildirebilirsiniz.")
              }
            })
            .catch((e) => {
              alert("İşleminiz sırasında bir hata oluştu: " + e.toString());
            });
          }

    validatePhone(phone){
      if(phone.length < 10)
        return "Lütfen en az 10 karakter uzunluğunda bir telefon numarası giriniz.";
      let phn = phone.split(" ").join("");
      phn = phn.split("-").join("");
      if(phn.charAt(0) === "0")
        phn = phn.substring(1);
      else if(phn.substring(0,3) === "+90")
        phn = phn.substring(3);
      if(isNaN(phn))
        return "Telefon numaranız yalnızca rakamlardan oluşmalıdır.";
      if(phn.length !== 10)
        return "Lütfen telefon numaranızın başındaki +90 ve 0 ifadeleri ile, numaranızdaki boşluk ve - karakterleri hariç, numaranın 10 rakam uzunluğunda olduğundan emin olunuz. ";
      this.setState({kTelno:phn});
      return "true";
    }

    validateEmail(email){
      return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    handleRegister()
    {
      this.setState({kayitHata:"", loginButtonLoading:true});
      if(this.state.kAd.length < 2)
      {
        this.setState({kayitHata:"Lütfen adınızı giriniz.", loginButtonLoading:false});
        return 0;
      }

      if(!this.validateEmail(this.state.kEposta))
      {
        this.setState({kayitHata:"Lütfen geçerli bir e-posta adresi giriniz.", loginButtonLoading:false});
        return 0;
      }

      const vp = this.validatePhone(this.state.kTelno);
      if(vp != "true")
      {
        this.setState({kayitHata:vp, loginButtonLoading:false});
        return 0;
      }

      if(this.state.kParola1.length < 6)
      {
        this.setState({kayitHata:"Lütfen en az 6 karakterden oluşan bir parola giriniz.", loginButtonLoading:false});
        return 0;
      }

      if(this.state.kSozlesme != true)
      {
        this.setState({kayitHata:"Kayıt olabilmek için Ön Kayıt Sözleşmesi'ni onaylamalısınız.", loginButtonLoading:false});
        return 0;
      }

      

      
      const FD = new FormData();
      FD.append("g-recaptcha-response", this.kRef.current.getValue());
      FD.append("name", this.state.kAd);
      FD.append("surname", " ");
      FD.append("email", this.state.kEposta);
      FD.append("telno", this.state.kTelno);
      FD.append("password", this.state.kParola1);
      FD.append("uyelik", this.state.kSozlesme);
      FD.append("aydinlatma", true);
      FD.append("tanitim", this.state.kTei);

      axios.post(server+"user/register.php", FD)
        .then((res) => {
          if(res.data.code === 100)
          {
            //window.gtag('event', 'conversion', {'send_to': 'AW-995593552/Ab0RCM-n7JQDENCa3toD'});
            //handleInfo(true, res.data.message);
            this.setState({kayitBasarilli:"Tebrikler, başarıyla kayıt oldunuz. İlginiz için teşekkür ederiz.", loginButtonLoading:false, kAd:"", kTelno:"", kEposta:"", kSozlesme:false, kParola1:""});
          }
          else
          {
            this.setState({kayitHata:res.data.message, loginButtonLoading:false});
            return 0;
          }
        })
        .catch((e) => {
          console.error("Ön kayıt sırasında ağ hatası!");
          console.error(e);
          this.setState({kayitHata:"Teknik hata (İletişim: destek@turkiyeelektronik.com): "+e.toString(), loginButtonLoading:false});
          return 0;
        });
    }

    render() {
        const { loading, error } = this.state;
        
        if(loading) { // if your component doesn't have to wait for async data, remove this block 
          //showLoader();
          return null; // render null when app is not ready
        }else {
          //hideLoader();
        }
        if(error) return (<ErrorPage/>); 
        else
        return (
          <>
          <div className="user-select-none">

          <Modal show={this.state.showPasswordReset} onHide={() => this.setState({showPasswordReset:false})} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{color:"black"}}>Parola Güncelleme</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {
          this.state.asama == 1 ?
          <>
          <div className="input-group form-group">
					  <div className="input-group-prepend">
						  <span className="input-group-text"><i className="bi bi-person-circle"></i></span>
						</div>
					  <input type="email" className="form-control" placeholder="Üye olurken girdiğiniz e-posta adresi" value={this.state.emailForRP} onChange={(e) => this.setState({emailForRP:e.target.value})} autoFocus/>
          </div>
          <Button variant="success" style={{width:"100%", textAlign:"center"}} onClick={() => this.handleResetPasswordS1()} disabled={this.state.loginButtonLoading} >{this.state.loginButtonLoading ? <Spinner animation="grow" variant="warning" /> : "Cep Telefonuma Doğrulama Mesajı Gönder"}</Button>
          </>
          : this.state.asama == 2 ?
          <>
          <div className="input-group form-group">
					  <div className="input-group-prepend">
						  <span className="input-group-text"><i className="bi bi-person-circle"></i></span>
						</div>
					  <input type="text" className="form-control" placeholder="Cep telefonunuza gönderilen doğrulama kodunu giriniz" value={this.state.vercode} onChange={(e) => this.setState({vercode:e.target.value})} autoFocus/>
          </div>
          <Button variant="success" style={{width:"100%", textAlign:"center"}} onClick={() => this.handleResetPasswordS2()} disabled={this.state.loginButtonLoading} >{this.state.loginButtonLoading ? <Spinner animation="grow" variant="warning" /> : "Kodu Doğrula"}</Button>
          </>
          : this.state.asama == 3 ?
          <>
          <div className="input-group form-group">
					  <div className="input-group-prepend">
						  <span className="input-group-text"><i className="bi bi-key-fill"></i></span>
						</div>
					  <input type="password" className="form-control" placeholder="Yeni parolanızı giriniz" value={this.state.newPassword} onChange={(e) => this.setState({newPassword:e.target.value})} autoFocus/>
          </div>
          <div style={{textAlign:"center", color:"red"}}>{this.state.newPassword != "" && this.state.newPassword.length < 6 ? "Parolanız 6 karakterden daha kısa olmamalıdır." : ""}</div>
          <Button variant="success" style={{width:"100%", textAlign:"center"}} onClick={() => this.handleResetPasswordS3()} disabled={this.state.loginButtonLoading} >{this.state.loginButtonLoading ? <Spinner animation="grow" variant="warning" /> : "Parolamı Güncelle"}</Button>
          </>
          : this.state.asama == 4 ?
          <>
          <h2 style={{color:"black"}}>Tebrikler!</h2>
          <center><i className="bi bi-check-circle-fill" width="128px" style={{color:"green"}}></i></center>
          <p style={{color:"black"}}>Parolanız başarıyla güncellendi. Yeni parolanızla giriş yapabilirsiniz.</p>
          </>
          :<></>
          }
          
          
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({showPasswordReset:false})}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>





          
            <div className="container" style={{marginBottom: "100px"}}>
                <Navbar/>
				<div className="row">
				
                <div className="col-md-5 registerPlaceholder">
                  <h1 className="girisTitle">Kayıt Ol</h1>
                  <hr/>
				  <div className="card-body">
					
					<div className="input-group form-group">
						<div className="input-group-prepend">
								<span className="input-group-text"><i className="bi bi-person-circle"></i></span>
						</div>
					    <input type="text" className="form-control" placeholder="Adınız & Soyadınız" value={this.state.kAd} onChange={(e) => this.handleChange(e, 7)}/>
					</div>

          <div className="input-group form-group">
						<div className="input-group-prepend">
								<span className="input-group-text"><i className="bi bi-envelope"></i></span>
						</div>
					    <input type="email" className="form-control" placeholder="E-posta Adresiniz" value={this.state.kEposta} onChange={(e) => this.handleChange(e, 8)}/>
					</div>
					
					<div className="input-group form-group">
						<div className="input-group-prepend">
								<span className="input-group-text"><i className="bi bi-telephone"></i></span>
						</div>
					    <input type="text" className="form-control" placeholder="Cep Telefonu Numaranız" value={this.state.kTelno} onChange={(e) => this.handleChange(e, 6)}/>
					</div>
					
					<div className="input-group form-group" style={{marginBottom:"0"}}>
						<div className="input-group-prepend">
							<span className="input-group-text"><i className="bi bi-key-fill"></i></span>
						</div>
						<input type="password" className="form-control" placeholder="Parola Belirleyiniz" value={this.state.kParola1} onChange={(e) => this.handleChange(e, 5)}/>
          </div>
          <small style={{color:"red", marginTop:"0"}}>{this.state.kParola1.length < 6 && this.state.kParola1.length != 0 ? "En az 6 karakter uzunluğunda bir parola giriniz." : ""}</small>
					<p>Kişisel Verilerin Korunması Hakkında Aydınlatma Metni'ne <Link to={process.env.PUBLIC_URL+"/yasal/kvk-aydinlatma-metni/"} target="_blank" style={{color:"aqua"}}>buraya tıklayarak</Link> ulaşabilirsiniz.</p>
          <p>Ön Kayıt Sözleşmesi'ne <Link to={process.env.PUBLIC_URL+"/yasal/on-kayit-sozlesmesi/"} target="_blank" style={{color:"aqua"}}>buraya tıklayarak</Link> ulaşabilirsiniz.</p>
					<div className="row align-items-center remember">
					    <input type="checkbox" checked={this.state.kSozlesme} onChange={(e) => this.handleChange(e, 3)} />Ön Kayıt Sözleşmesi'ni Kabul Ediyorum.
					</div><br/>
          <div className="row align-items-center remember">
					    <input type="checkbox" checked={this.state.kTei} onChange={(e) => this.handleChange(e, 9)} />Tarafıma gelişmelerin, kampanyaların ve benzeri faydaların bildirilmesi için e-posta ve SMS yollarıyla ticari içerikli elektronik iletilerin gönderilmesine izin veriyorum.
					</div><br/>
					
          <ReCAPTCHA
              sitekey="6LfYmfkdAAAAAEN_4O8A8Rse67FnY2Wg_9g4WiBr"
              ref={this.kRef}
            />
          <p style={{color:"#d6f3ff"}}>{this.state.kayitHata != "" ?   <><b style={{color:"red"}}>Bir hata oluştu: </b>{this.state.kayitHata}</> : ""}</p>
          <div className="form-group">
						<button type="submit" className="btn float-right login_btn" disabled={this.state.loginButtonLoading} onClick={() => this.handleButton(3)}>
							{this.state.loginButtonLoading ? <Spinner animation="grow" variant="success" /> : "Kayıt Ol"}
						</button>
            <p style={{color:"#baffbf"}}>{this.state.kayitBasarilli}</p>
					</div>
					<br/>
					
				  </div>
                </div>
                
                <div className="col-md-2"></div>

                <div className="col-md-5 loginPlaceholder">
					<h1 className="girisTitle">Giriş Yap</h1>
					<hr/>
					<div className="card-body">
						<div className="input-group form-group">
							<div className="input-group-prepend">
								<span className="input-group-text"><i className="bi bi-person-circle"></i></span>
						    </div>
					        <input type="email" className="form-control" placeholder="E-posta Adresiniz" value={this.state.usernameL} onChange={(e) => this.handleChange(e, 0)}/>
					    </div>
					    <div className="input-group form-group">
						    <div className="input-group-prepend">
						        <span className="input-group-text"><i className="bi bi-key-fill"></i></span>
						    </div>
						    <input type="password" className="form-control" placeholder="Parolanız" value={this.state.passwordL} onChange={(e) => this.handleChange(e, 1)}/>
					    </div>
					    <div className="row align-items-center remember">
					        <input type="checkbox" checked={this.state.rmMe} onChange={(e) => this.handleChange(e, 2)} />Beni hatırla
					    </div>
					    <div className="form-group">
                <Button variant="outline-warning" size="sm" style={{marginTop:"10px"}} onClick={() => this.setState({showPasswordReset:true})}>Parolamı Unuttum</Button>
						    <button type="submit" className="btn float-right login_btn" disabled={this.state.loginButtonLoading} onClick={() => this.handleButton(0)}>
								{this.state.loginButtonLoading ? <Spinner animation="grow" variant="success" /> : "Giriş Yap"}
							</button>
              <br/><br/><br/><hr/><br/><br/>
              <p style={{color:"white"}}>Giriş işlemiyle ilgili sorun yaşamanız durumunda sayfanın altındaki iletişim bölümünden veya <a style={{color:"black"}} href="mailto:destek@turkiyeelektronik.com">destek@turkiyeelektronik.com</a> adresinden bize ulaşabilirsiniz. </p>
					    </div>
						<br/>
			        </div>
                </div>
              </div>
			  </div>
            <Footer/>
        </div>
        </>
        ); 
      }
    }


    export default Login;