import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

class Vote extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            selectedStar:0,
            yellow:[0,0,0,0,0],
            checkbox:true,
            checkbox2:false
        };
        this.global = {oldYellow:[0,0,0,0,0], ome:false, comment:""};
    }

    starClicked(star)
    {
        let newYellow = [0,0,0,0,0];
        const starId = star - 1;
        this.global.ome = false;

        if(starId == 0)
        {
            //ilk yıldız
            newYellow = [1,0,0,0,0];
        }else if(starId == 1){
            //ikinci yıldız
            newYellow = [1,1,0,0,0];
        }else if(starId == 2){
            //üçüncü yıldız
            newYellow = [1,1,1,0,0];
        }else if(starId == 3)
        {
            //dördüncü yıldız.
            newYellow = [1,1,1,1,0];
        }else{
            //beşinci yıldız
            newYellow = [1,1,1,1,1];
        }

        this.props.callback("star", star);
        this.global.oldYellow = newYellow;
        this.setState({selectedStar:star, yellow:newYellow});
    }

    onMouseEnter(star)
    {
        if(!this.global.ome) 
            this.global.oldYellow = this.state.yellow;
        
        this.global.ome = true;
        let newYellow = [0,0,0,0,0];
        const starId = star - 1;

        if(starId == 0)
        {
            //ilk yıldız
            newYellow = [1,0,0,0,0];
        }else if(starId == 1){
            //ikinci yıldız
            newYellow = [1,1,0,0,0];
        }else if(starId == 2){
            //üçüncü yıldız
            newYellow = [1,1,1,0,0];
        }else if(starId == 3)
        {
            //dördüncü yıldız.
            newYellow = [1,1,1,1,0];
        }else{
            //beşinci yıldız
            newYellow = [1,1,1,1,1];
        }
        
        this.setState({yellow:newYellow});

    }

    onMouseOut()
    {
        this.setState({yellow:this.global.oldYellow});
    }

    handleCheckBox(event)
    {
        this.props.callback("homepage", event.target.checked);
        this.setState({checkbox:event.target.checked});
    }
    handleCheckBox2(event)
    {
        this.props.callback("name", event.target.checked);
        this.setState({checkbox2:event.target.checked});
    }

    render(){
        const starFilled = "bi bi-star-fill text-warning";
        const starNotFilled = "bi bi-star text-warning";
        return(
            <>
            Lütfen deneyiminizi aşağıdaki yıldızlara tıklayarak oylayınız.<br/>
        <center>
            <i className={this.state.yellow[0] ? starFilled : starNotFilled}
            style={{ fontSize: 30 }}
            onClick={() => this.starClicked(1)}
            onMouseEnter={() => this.onMouseEnter(1)}
            onMouseOut ={() => this.onMouseOut()}
            ></i>

            <i className={this.state.yellow[1] ? starFilled : starNotFilled}
            style={{ fontSize: 30 }}
            onClick={() => this.starClicked(2)}
            onMouseEnter={() => this.onMouseEnter(2)}
            onMouseOut ={() => this.onMouseOut()}
            ></i>

            <i className={this.state.yellow[2] ? starFilled : starNotFilled}
            style={{ fontSize: 30 }}
            onClick={() => this.starClicked(3)}
            onMouseEnter={() => this.onMouseEnter(3)}
            onMouseOut ={() => this.onMouseOut()}
            ></i>

            <i className={this.state.yellow[3] ? starFilled : starNotFilled}
            style={{ fontSize: 30 }}
            onClick={() => this.starClicked(4)}
            onMouseEnter={() => this.onMouseEnter(4)}
            onMouseOut ={() => this.onMouseOut()}
            ></i>

            <i className={this.state.yellow[4] ? starFilled : starNotFilled}
            style={{ fontSize: 30 }}
            onClick={() => this.starClicked(5)}
            onMouseEnter={() => this.onMouseEnter(5)}
            onMouseOut ={() => this.onMouseOut()}
            ></i>
        </center><br/>
        Lütfen bize görüşlerinizi belirtiniz.
        <CKEditor
            editor={ ClassicEditor }
            data={this.global.comment}
            config={{placeholder:"Yorumunuzu buraya yazabilirsiniz..."}}
            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
            }}
            onChange={ ( event, editor ) => {
                this.global.comment = editor.getData();
                this.props.callback("comment", editor.getData());
            }}
        />
        <br/>
        <Form.Group controlId="cb1">
            <Form.Check type="checkbox" label="Yorumumun ana sayfada yayımlanabilmesine izin veriyorum." checked={this.state.checkbox} onChange={(event) => this.handleCheckBox(event)}/>
        </Form.Group>
        
        {
            this.state.checkbox ?
            <Form.Group controlId="cb2">
                <Form.Check type="checkbox" label="Yayımlanan yorumda ismim gözüksün." checked={this.state.checkbox2} onChange={(event) => this.handleCheckBox2(event)}/>
            </Form.Group>
            : ""
        }
        {
            this.state.checkbox ? <Alert variant="warning">Ana sayfamızda yayımlamak üzere seçtiğimiz yorumlarda dil bilgisi hatası olması 
            durumunda bu hataları gidermekle sınırlı kalacak şekilde yorumunuzda güncelleme yapabiliriz. <br/>
            Ana sayfada yayımlanacak yorumlar seçilmektedir.</Alert> : ""
        }
            </>
        )
    }
}

export default Vote;