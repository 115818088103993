import React from 'react';
import axios from 'axios';
import server from './config';
import history from './history';
import Spinner from 'react-bootstrap/Spinner';

class Logout extends React.Component{
    constructor(props)
    {
        super(props);
        //oturum tokeni mevcut mu?
        if(localStorage.session !== undefined && localStorage.session !== 'undefined')
        {
            //bir oturum tokeni mevcut.
            const data = {token: localStorage.session};
            axios.post(server+"user/logout.php", data)
            .then((res) => {
                //başarılı
                localStorage.removeItem("session");
                history.push("/");
            })
            .catch(() => {
                //TODO: Hata ekranını göster.
                console.error("Network error while logout");
                localStorage.removeItem("session");
                history.push("/");
            })
        }else{
            history.push("/");
        }
    }

    render(){
        return(<>
        <div style={{textAlign:"center", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            <Spinner animation="border" variant="info" style={{height:"64px", width:"64px"}} />
            <h2>Çıkış Yapılıyor...</h2>
        </div>
        </>);
    }
}

export default Logout;