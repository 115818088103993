import React from 'react';
import axios from 'axios';
import history from './history';
import server from './config';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';

class Footer extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            contact:false,
            contactLoading:false,
            konu:"",
            ad:"",
            email:"",
            mesaj:"" 
        };
        this.cref = React.createRef();
    }

    validateEmail(email){
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    handleContact()
    {
        this.setState({contactLoading:true});
        if(this.state.konu.length < 2)
        {
            alert("Lütfen konuyu giriniz.");
            return 0;
        }

        if(this.state.ad.length < 3)
        {
            alert("Lütfen adınızı giriniz.");
            return 0;
        }

        if(this.state.email.length < 3)
        {
            alert("Lütfen e-posta adresinizi giriniz.");
            return 0;
        }

        if(!this.validateEmail(this.state.email))
        {
            alert("Lütfen geçerli bir e-posta adresi giriniz.");
            return 0;
        }

        if(this.state.mesaj.length < 3)
        {
            alert("Lütfen mesajınızı giriniz.");
            return 0;
        }

        const FD = new FormData();
        FD.append("g-recaptcha-response", this.cref.current.getValue());
        FD.append("subject", this.state.konu);
        FD.append("name", this.state.ad);
        FD.append("email", this.state.email);
        FD.append("message", this.state.mesaj);
        
        axios.post(server+"preRegister/contact.php", FD)
        .then((res) => {

            if(res.data.code === 100)
            {
                alert("Mesajınız başarıyla gönderildi.");
                this.setState({contactLoading:false, ad:"", konu:"", email:"", mesaj:""});
            }else{
                alert("Mesaj gönderilirken hata: " + res.data.message)
                this.setState({contactLoading:false});
            }
        })
        .catch((e) => {
            console.error("İletişim verisi gönderilirken ağ hatası!");
            console.error(e);
            alert("Mesaj gönderilirken hata: " + e.toString());
            this.setState({contactLoading:false});
        });
    }

    render(){
        return(
            <>
            <Modal show={this.state.contact} onHide={() => this.setState({contact:false})} centered style={{color:"black"}}>
      <Modal.Header closeButton>
        <Modal.Title>İletişim Formu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="konu">
          <Form.Label>Konu</Form.Label>
          <Form.Control type="text" name="subject" placeholder="Lütfen konuyu yazınız." value={this.state.konu} onChange={(e) => this.setState({konu:e.target.value})} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="isim">
          <Form.Label>Adınız - Soyadınız</Form.Label>
          <Form.Control type="text" name="name" placeholder="Adınızı giriniz" value={this.state.ad} onChange={(e) => this.setState({ad:e.target.value})} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>E-posta Adresiniz</Form.Label>
          <Form.Control type="email" name="email" placeholder="E-posta Adresiniz" value={this.state.email} onChange={(e) => this.setState({email:e.target.value})} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="message" style={{width:'100%'}}>
          <Form.Label>Mesajınız</Form.Label><br/>
          <textarea style={{width:"100%"}} name="message" placeholder='Lütfen mesajınızı yazınız.' value={this.state.mesaj} onChange={(e) => this.setState({mesaj:e.target.value})}></textarea>
        </Form.Group>
        <Form.Group className="mb-3">
          <div style={{textAlign:"center"}}>
          <ReCAPTCHA
              sitekey="6LfYmfkdAAAAAEN_4O8A8Rse67FnY2Wg_9g4WiBr"
              ref={this.cref}
            />
          </div>
        </Form.Group>
        <div>Kişisel Verilerin Korunması Hakkında Aydınlatma Metni'ne ulaşmak için <a href="https://turkiyeelektronik.com/yasal/kvk-aydinlatma-metni/" rel="noreferrer" target="_blank">buraya</a> tıklayınız.</div><br/>
        <Form.Group className="mb-3">
          <Button style={{width:"100%"}} variant="success" onClick={() => this.handleContact()} disabled={this.state.contactLoading} >
            {
              this.state.contactLoading ? <Spinner animation="grow" variant="warning" /> : "Gönder"
            }
          </Button>
        </Form.Group>

      </Modal.Body>
    </Modal>
            <div className="footer bg-dark" style={{width:"100%"}}>Copyright &copy; 2022 Tüm hakları saklıdır. <br/>
                <div className='row' style={{width:"100%"}}>
                    <div className='col-md-3'><Link to={process.env.PUBLIC_URL+"/yasal/kvk-aydinlatma-metni/"}>Kişisel Verilerin Korunması Hk. Aydınlatma Metni</Link></div>
                    <div className='col-md-3'><Link to={process.env.PUBLIC_URL+"/yasal/on-kayit-sozlesmesi/"}>Ön Kayıt Sözleşmesi</Link></div>
                    <div className='col-md-3'><a href="#iletisim" onClick={() => this.setState({contact:true})}>İletişim</a></div>
                    <div className='col-md-3'><img style={{float:"right"}} src="https://images.turkiyeelektronik.com/logoCompressedSmall.png" width="48px" alt="TürkiyeElektronik Logosu" /></div>
                </div>
            </div>
            </>
        )
    }
}

export default Footer;