import React from 'react';
import axios from 'axios';
import JsxParser from 'react-jsx-parser';
import server from './config';

import Alert from 'react-bootstrap/Alert';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';

import Navbar from './Navbar';
import Footer from './Footer';

class Cekilisler extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {error:false, sweepstakes:[]};
        this.global = {listRunned:false};

        axios.get(server+"sweepstake/getList.php")
        .then((res) => {
            this.setState({sweepstakes: res.data});
        })
        .catch(() => {
            this.setState({error:true});
        });
    }

    saveUser(id)
    {
        const data = {token: localStorage.session, sid:id};
        axios.post(server+"sweepstake/saveUser.php", data)
        .then((res) => {
            if(res.data === 1)
            {
                console.log("çekilişe başarıyla katılım sağlandı");
            }else{
                console.error("çekilişe katılım sırasında hata oluştu");
            }
        })
        .catch(() => {
            console.error("Çekilişe katılma denemesi sırasında sunucu hatası");
        });
    }

    render()
    {
        return(
            <>
            <Navbar/>
            <div className = "container">

                {
                    
                    this.state.sweepstakes.map((val,i) => {
                        this.global.listRunned = true;
                        return(
                        <Jumbotron className="bg-dark">
                            <h1>{val.name}</h1>
                            <JsxParser jsx={val.description} />
                            <p><Button variant="primary" onClick={() => this.saveUser(val.id)} >Katıl</Button></p>
                        </Jumbotron>);
                    })
                }
                {
                    this.global.listRunned ? "" : <Alert variant="warning">Şu anda kullanıma açık bir çekiliş bulunmuyor.</Alert>
                }
            </div>
            <Footer/>
            </>
        );
    }
}

export default Cekilisler;