import React from 'react';
import axios from 'axios';
import JsxParser from 'react-jsx-parser';
import server from '../common/config';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';




class Yasal extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = {
            pageData:"Yükleniyor..."
        }
        this.global = {oldName:this.props.match.params.name}

    }


    updateData()
    {
        console.log("updating data");
        axios.get(server+"preRegister/getPageData/"+this.props.match.params.name)
        .then((res) => {
            this.setState({pageData:res.data});
        })
        .catch((e) => {
            console.error(e);
            this.setState({pageData:"Sayfa verisi çekilirken hata: ("+e.toString()+") Bizimle destek@turkiyeelektronik.com adreinden iletişime geçebilirsiniz."});
        });
    }

    rendered(){
        console.log("rendered");
        if(this.global.oldName != this.props.match.params.name)
        {
            this.global.oldName = this.props.match.params.name;
            this.updateData();
            window.scrollTo(0, 0);
        }
    }

    componentDidMount()
    {
        this.updateData();
    }

    render()
    {
        this.rendered();
        return(<>
        <Navbar/>
        <div className="container" style={{marginBottom:"150px"}}>
            <JsxParser jsx={this.state.pageData} />
        </div>
        <Footer/>
        </>);
    }
}

export default Yasal;