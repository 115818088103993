import { Spinner } from "react-bootstrap";
function Loading() {
  return (
    <>
    <div style={{textAlign:"center", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
      <Spinner animation="border" variant="info" style={{height:"64px", width:"64px"}} />
      <h1>Yükleniyor...</h1>
    </div>
    </>
  );
}

export default Loading;
