import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './common/common.css';
import './course/Course.css';

import ErrorPage from './common/ErrorPage';
import Home from './common/Home';
import Login from './common/Login';
import Logout from './common/Logout';
import Cekilisler from './common/Cekilisler';
import Page from './common/Page';
import CourseLister from './course/CourseLister';
import Course from './course/Course';
import CoursePlayer from './course/CoursePlayer';
import ProjectPlayer from './course/ProjectPlayer';
import QuizPlayer from './course/QuizPlayer';
import reportWebVitals from './reportWebVitals';
import Yasal from './common/Yasal';

import { Router, Route, Switch } from 'react-router-dom';
import history from './common/history';
ReactDOM.render(
  <React.StrictMode>
	  <div className="user-select-none">
    <Router history={history}>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/giris" component={Login} />
			<Route path="/cikis" component={Logout} />
			<Route path="/cekilisler" component={Cekilisler} />
			<Route path="/ders/:name/:lessonName/:sublesson" component={CoursePlayer}/>
			<Route path="/proje/:pid" component={ProjectPlayer}/>
			<Route path="/kurslar/quizPlayer/:quiz" component={QuizPlayer}/>
			<Route path="/kurslar/:name" component={Course}/>
			<Route path="/kurslar" component={CourseLister} />
			<Route path="/yasal/:name" component={Yasal} />
			<Route path="/:pageName" component={Page} />
			<Route component={ErrorPage} />
		</Switch>
	</Router>
	</div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
