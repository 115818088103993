import React from 'react';
import history from './history';
import axios from 'axios';
import server from './config';
import Spinner from 'react-bootstrap/Spinner';
import { Navbar as Navbar1, Nav, Container } from 'react-bootstrap';
class Navbar extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            userLoginedIn: false,
            loading:true
        }
    }

    componentDidMount()
    {
        let data = {token:localStorage.session};
        axios.post(server+"user/checkLogin.php", data)
        .then((res) => {
            if(res.data === 1)
                this.setState({userLoginedIn:true, loading:false});
            else
                this.setState({userLoginedIn:false, loading:false});
        })
        .catch((e) => {
            console.error(e);
            this.setState({userLoginedIn:false, loading:false});
        });
    }

    render(){
        return(
            <>
            <Navbar1 bg="dark" variant="dark" collapseOnSelect expand="sm" fixed="top">
                <Container>
                    <Navbar1.Brand href="#anasayfa" onClick={() => history.push("/")}>TürkiyeElektronik Kursları</Navbar1.Brand>
                    <Navbar1.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar1.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#anasayfa" onClick={() => history.push("/")}>Ana Sayfa</Nav.Link>
                            {
                                this.state.loading ? <Spinner animation="grow" variant="secondary" /> :
                                this.state.userLoginedIn ?
                                <>
                                <Nav.Link href="#cikis" onClick={() => history.push("/cikis")}>Çıkış Yap</Nav.Link>
                                </> : 
                                <>
                                <Nav.Link href="#giris" onClick={() => history.push("/giris")}>Giriş Yap - Kayıt Ol</Nav.Link>
                                </>
                            }
                        </Nav>
                    </Navbar1.Collapse>
                </Container>
            </Navbar1>           
            </>
        );
    }
}

export default Navbar;


/**
 * 
  <nav className="navbar  navbar-dark bg-dark">
                <span className="navbar-brand mb-0 h1">TürkiyeElektronik Kursları</span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <a className="nav-link active" href="#anasayfa" >Ana Sayfa</a>
                        <a className="nav-link" href="#ozellikler">Features</a>
                        <a className="nav-link" href="#giris" >Giriş</a>
                        <a className="nav-link" href="#cikis" >Çıkış</a>
                        <a className="nav-link disabled" href="#disabled" tabIndex="-1" aria-disabled="true">Disabled</a>
                    </div>
                </div>
            </nav>
 */