import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import JsxParser from 'react-jsx-parser';

class Questions extends React.Component
{
    constructor(props)
    {
        super(props);
        //courseQuestions
        //global
        //handleQuiz

    }

    render()
    {
        return(
            <Jumbotron className="bg-dark">
                    {this.props.courseQuestions.length === 0 ? <Alert variant="info" >Bu içerik için soru bulunamadı.</Alert> : ""}
                    {
                        this.props.courseQuestions.map((question, i) => {
                            this.props.global.questionAnswers[i] = [];
                            return(
                                <><span>Soru <b>{(i+1).toString()}/{this.props.courseQuestions.length.toString()}</b>:</span>
                                <JsxParser key={"q"+i.toString()} jsx={question.text}/>
                                {
                                    question.answers.map((answer,q) => {
                                        while(this.props.global.questionAnswers[i].length !== question.answers.length)
                                        {
                                            let id = this.props.getRandomInt(question.answers.length);
                                            if(! this.props.global.questionAnswers[i].includes(id))
                                            {
                                                this.props.global.questionAnswers[i].push(id);

                                                return(<><Button style={{margin:"5px", width:"100%"}} key={"q"+i.toString()+"a"+id.toString()} onClick={(item) => this.props.handleQuiz(question.id, question.answers[id].id, item)}>{question.answers[id].text}</Button><br/></>);
                                            }
                                        }
                                        return(<></>);
                                    })
                                }
                                {i+1 === this.props.courseQuestions.length ? "" : <hr/>}
                                </>
                            );
                        })
                    }
                </Jumbotron>
        )
    }
}

export default Questions;