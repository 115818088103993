import '../App.css';
import history from './history';
import Button from 'react-bootstrap/Button';

function ErrorPage() {
  return (
    <>
    <div className="errorPageMain">
      <svg xmlns="http://www.w3.org/2000/svg" width="20%" fill="yellow" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </svg>
      <h1>Bir hata oluştu!</h1>
      <p><b>Buna şunlar sebep olmuş olabilir:</b></p>
        <div style={{margin:"auto", width:"fit-content"}}>
          <ul>
            <li>İnternet bağlantı problemleri</li>
            <li>Var olmayan bir sayfaya ulaşmaya çalışmanız</li>
            <li>Yetki gerektiren bir sayfaya ulaşmaya çalışıyor olmanız</li>
            <li>Bu sayfa ücretli içeriklerimizden birine ait olabilir</li>
            <li>Görüntülediğiniz sayfa bozulmuş olabilir</li>
            <li>Sunucularımızda geçici bir problem olabilir</li>
          </ul>
          <div style={{textAlign:"left", margin:"auto", width:"fit-content"}}>
          Sorun devam ederse lütfen destek@turkiyeelektronik.com adresine bir elektronik posta gönderiniz.<br/>
          </div>
          <Button variant="success" onClick={() => history.push(process.env.PUBLIC_URL)}>Ana Sayfa'ya Git</Button>
        </div>
    </div>
    </>
  );
}

export default ErrorPage;
