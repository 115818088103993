import React from 'react';
import axios from 'axios';
import JsxParser from 'react-jsx-parser';
import server from '../common/config';
import Navbar from '../common/Navbar';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Footer from '../common/Footer';
import Alert from 'react-bootstrap/Alert';



class QuizPlayer extends React.Component{
    render()
    {
        return(<>
        <Navbar/>
        <div className="container">
            <p>Değerli Üyemiz,<br/>Sınav sistemimize bakım çalışması uygulamaktayız. Anlayışınız için teşekkür ederiz.</p>
        </div>
        <Footer/>
        </>);
    }
}

/*
class QuizPlayer extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            error:false, 
            questions:[]
        };

        this.global = {
            questionAnswers:[], 
            answers:{},
            temp:{},
            orderSaved:false,
            savedOrded:[]
        };
        this.handleQuiz = this.handleQuiz.bind(this);
    }

    componentDidMount()
    {
        let data = {
            token:localStorage.session,
            quiz:this.props.match.params.quiz,
            answered:false
        };
        axios.post(server+"course/getQuiz.php", data)
        .then((res) => {
            if(res.data !== 0) {
                this.setState({questions:res.data});
                //soruları sıralayarak global'e kaydet.
                res.data.forEach((question, i) => {
                    let temp = {id:question.id, text:question.text, answers:[]};
                    while(temp.answers.length != question.answers.length)
                    {
                        //0 ile n-1 aralığında rastgele bir sayı seç.
                        
                    }
                    console.log(question);
                });
                console.log(res.data);
            }
            else{
                this.setState({error:true});
                console.error("Sınav soruları alınırken hata oluştu.");
            }
        })
        .catch((e) => {
            console.error(e);
            this.setState({error:true});
            console.error("Sınav soruları alınırken sunucu hatası");
        });
    }

    handleQuiz(question, answer)
      {
        let data = {
            token:localStorage.session,
            quiz:this.props.match.params.quiz,
            answered:true,
            question:question,
            answer:answer
        };
        axios.post(server+"course/getQuiz.php",data)
        .then((res) => {
            if(res.data === 0)
            {
                this.setState({error:true});
                console.error("Soru cevabını kontrol ederken hata oluştu.");
            }else if(res.data === 1){
                if(this.global.answers[question] === "undefined" || this.global.answers[question] === undefined)
                    this.global.answers[question] = {};
                this.global.answers[question][answer] = 1;
                this.forceUpdate();

                alert("doğru yanıt");
            }else if(res.data === 2){
                if(this.global.answers[question] === "undefined" || this.global.answers[question] === undefined)
                    this.global.answers[question] = {};
                this.global.answers[question][answer] = 2;
                this.forceUpdate();
                alert("Yanıtınızı kontrol edin.");
            }else{
                this.setState({error:true});
                console.error("Soru cevabını kontrol ederken bilinmeyen cevap");
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("Soru cevabını kontrol ederken sunucu hatası");
        });

      }

    getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    render()
    {
        return(
        <>
        <Navbar/>
        <div className="container">
            <h2 style={{textDecoration:"underline", paddingTop:"30px"}}>Sınav</h2>
            
            <Jumbotron style = {{marginBottom:"0px"}} className="bg-dark">
                {this.state.questions.length === 0 ? <Alert variant="danger">Bu sınav için soru bulunamadı.</Alert> : ""}
                {
                    this.state.questions.map((question, i) => {
                        this.global.questionAnswers[i] = [];
                        return(
                            <><span>Soru <b>{(i+1).toString()}/{this.state.questions.length.toString()}</b>:</span>
                            <JsxParser key={"q"+i.toString()} jsx={question.text}/>
                            {
                                question.answers.map((answer,q) => {
                                    while(this.global.questionAnswers[i].length !== question.answers.length)
                                    {
                                        let id = this.getRandomInt(question.answers.length);
                                        if(! this.global.questionAnswers[i].includes(id))
                                        {
                                            this.global.questionAnswers[i].push(id);
                                            return(
                                            <><Button 
                                                style={{margin:"5px", width:"100%"}} 
                                                key={"q"+i.toString()+"a"+id.toString()} 
                                                onClick={() => this.handleQuiz(question.id, question.answers[id].id)}
                                                disabled={this.global.answers[question.id] === undefined || this.global.answers[question.id] === "undefined" ? false : 
                                                this.global.answers[question.id][question.answers[id].id] === undefined || 
                                                this.global.answers[question.id][question.answers[id].id] === "undefined" ? false : true }

                                                variant={this.global.answers[question.id] === undefined || this.global.answers[question.id] === "undefined" ? "primary" : 
                                                this.global.answers[question.id][question.answers[id].id] === undefined || 
                                                this.global.answers[question.id][question.answers[id].id] === "undefined" ? "primary" : 
                                                this.global.answers[question.id][question.answers[id].id] === 1 ? "success" : "danger"
                                            }

                                            >{question.answers[id].text}</Button><br/></>);
                                        }
                                    }
                                    return;
                                })
                            }
                            {i+1 === this.state.questions.length ? "" : <hr/>}
                            </>
                        );
                    })
                }
            </Jumbotron>
        </div>
        <Footer/>
        </>
        );
    }
}
*/
export default QuizPlayer;