import React from 'react';
import axios from 'axios';
import server from '../common/config';
import Navbar from '../common/Navbar';
import Loading from '../common/Loading';
import ErrorPage from '../common/ErrorPage';
import Breadcrump from './Breadcrumb';
import history from '../common/history';
import Footer from '../common/Footer';
import Alert from 'react-bootstrap/Alert';


class CourseLister extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {error:false, loading:true, buyedCourses:[], nonBuyedCourses:[], warningMessage: "Katılabileceğiniz kurs bulunamadı. Aşağıdaki menüden bir kurs aktive etmeyi deneyebilirsiniz.", message2:""};
        this.global = {listRunned:false, list2Runned:false, loaded:0, loadable:2};
    }

    componentDidMount()
    {
        const data = {token:localStorage.session};
        axios.post(server+"course/getBuyedCourseList.php",data)
        .then((res) =>{
            if(res.data === 0)
            {
                //bir hata oluştu
                console.error("Satın alınan kurs listesi çekilirken 0 döndü.");
                this.setState({warningMessage:"Şu anda katılabileceğiniz bir kurs bulunmuyor. Aşağıdaki listeden bir tanesini satın almayı deneyebilirsiniz."});
            }else{
                this.setState({buyedCourses:res.data});
            }
            this.global.loaded++;
            if(this.global.loaded === this.global.loadable)
                this.setState({loading:false});
        })
        .catch(() => {
            console.error("Kurs listesi alınırken SUNUCU HATASI!");
            this.setState({error:true});
        });

        ///////////////////////////////////////////////////////////////////

        axios.post(server+"course/getNonBuyedCourseList.php",data)
        .then((res) =>{
            if(res.data === 0)
            {
                //bir hata oluştu
                console.error("Satın alınmayan kurs listesi çekilirken 0 döndü.");
                this.setState({message2:"Şu anda satın bir kurs bulunmuyor. Aşağıdaki listeden bir tanesini satın almayı deneyebilirsiniz."});
            }else{
                this.setState({nonBuyedCourses:res.data});
            }
            this.global.loaded++;
            if(this.global.loaded === this.global.loadable)
                this.setState({loading:false});
        })
        .catch(() => {
            console.error("Kurs listesi alınırken SUNUCU HATASI!");
            this.setState({error:true});
        });
    }

    render(){
        if(this.state.error)
            return(<ErrorPage/>);
        else if(this.state.loading)
            return(<Loading/>);
        else
        return(
        <div>
            <div className="container">
                <Navbar/>
                <p className="kursListeUst">Katılabileceğiniz Kurslar:</p>
                <div className="overflow-auto course">

                {
                    this.state.buyedCourses.map((val,i) => {
                        this.global.listRunned = true;
                        return(
                            <div key={i} onClick={() => history.push(process.env.PUBLIC_URL+"/kurslar/"+val.url)} className="shadow p-3 mb-5 bg-dark rounded align-items-center courseContent">
                                <img src={val.icon} alt="logo" /> 
                                <h4 className="courseTitleLeft">{val.name}</h4>
                            </div>
                        );
                        /*return(
                            <div key={i} onClick={() => history.push(process.env.PUBLIC_URL+"/kurslar/"+val.url)} className="shadow p-3 mb-5 bg-dark rounded align-items-center courseContent">
                                <img src={val.icon} alt="logo" /> 
                                <h4 className="courseTitleLeft">{val.name}</h4>
                            </div>
                        );*/
                    })
                }

                {
                    this.global.listRunned ? "" : <Alert variant="warning">{this.state.warningMessage}</Alert>
                }
                </div><br/>
                <p className="kursListeUst">Aktive Edebileceğiniz Kurslar:</p>
                <div className="overflow-auto course">

                {
                    this.state.nonBuyedCourses.map((val,i) => {
                        this.global.list2Runned = true;
                        return(
                            <div key={i} onClick={() => history.push(process.env.PUBLIC_URL+"/kurslar/"+val.url)} className="shadow p-3 mb-5 bg-dark rounded align-items-center courseContent">
                                <img src={val.icon} alt="logo" /> 
                                <h4 className="courseTitleLeft">{val.name}</h4>
                            </div>
                        );
                        /*return(
                            <div key={i} onClick={() => history.push(process.env.PUBLIC_URL+"/kurslar/"+val.url)} className="shadow p-3 mb-5 bg-dark rounded align-items-center courseContent">
                                <img src={val.icon} alt="logo" /> 
                                <h4 className="courseTitleLeft">{val.name}</h4>
                            </div>
                        );*/
                    })
                }

                {
                    this.global.list2Runned ? "" : <Alert variant="success">Bütün kursları aktive etmişsiniz.</Alert>
                }
                </div>
            </div>
            <Footer/>
        </div>
        );
    }
}

export default CourseLister;