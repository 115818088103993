import React from 'react';
import axios from 'axios';
import ErrorPage from '../common/ErrorPage';
import Loading from '../common/Loading';
import JsxParser from 'react-jsx-parser';
import server from '../common/config';
import history from '../common/history';
import VideoPlayer from '../common/VideoPlayer';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import Vote from './Vote';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Questions from './Questions';
import Spinner from 'react-bootstrap/Spinner';
import { renderToString } from 'react-dom/server'
import Badge from 'react-bootstrap/Badge';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';

//import SyntaxHighlighter from 'react-syntax-highlighter';
//import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
//import xhook from 'xhook';

class CoursePlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            error:false,
            errorMessage:"",
            voted:false,
            code: 'print(125)', 
            courseName:{name:"Yükleniyor...", sublesson:"Yükleniyor..."},
            courseLessons:[],
            courseContent:{text:"<p>Yükleniyor...</p>", video:"", videoExpires:0, videoToken:"", quizComplated:false },
            courseQuestions:[],
            show:false,
            showVote:false,
            showAnswerModel:false,
            showToast:false,
            tabKey: 'send',

            answersLoading:true,
            answerList: [],
            selectedQuestion: -1,
            msgLoading: true,
            messageList: [],
            msgError:"",
            sendLoading:false,
            unreadAnswerCnt:0,

            showNotification:false,
            notificationHeader:"",
            notificationBody:""

        };
        this.global = {
            questionAnswers:{}, 
            trueAnswers:0, 
            quizComplated:false, 
            wrongTryCount:0, 
            helpData:'',
            voteData:{star:0, comment:"", homepage:true, name:false},
            helpAnswerData: ""
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.vote = this.vote.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleQuiz = this.handleQuiz.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        this.sendMessageRef = React.createRef();
        this.getAnswerList();
        this.getNotifications(true);

        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
        }

        const interval = setInterval(() => {
            this.getNotifications(false);
        },5000);
        console.log("Interval ID: "+interval.toString());
      }

      getNotifications(first)
      {
          let fd = new FormData();
          fd.append("token", localStorage.session);
          fd.append("first", first ? "true" : "false");
          axios.post(server+"course/getNotifications.php",fd)
          .then((res) => {
              if(res.data.status == 100)
              {
                    if(res.data.header != "" && res.data.body != "")
                    {
                        this.setState({showNotification:true, notificationHeader: res.data.header, notificationBody: res.data.body});
                        this.getAnswerList();
                    }
                }else{
                  console.error("");
              }
          })
          .catch((e) => {
              console.error("Bildirimler çekilirken hata");
              console.error(e);
          });
      }

      getData()
      {
        let loadedItems = 0;
        const loadableItems = 6;

        let data = {token:localStorage.session, course:this.props.match.params.name, lesson:this.props.match.params.sublesson};
        if(!this.state.error)
        axios.post(server+"course/checkIfUserProvidePrerequisites.php",data)
        .then((res) => {
            if(res.data === 0)
            {
                //hata veya ön koşullar sağlanmadı.
                this.setState({errorMessage:"Bu kurs için gereken ön koşulları sağlamadınız. Lütfen önceki dersleri tamamlayıp tekrar deneyin.", error:true});
                data = {token:localStorage.session, course:this.props.match.params.name, lesson:this.props.match.params.sublesson};
                axios.post(server+"course/getRequiredPrerequisites.php",data)
                .then((res) => {
                    if(res.headers['content-type'] === "application/json; charset=utf-8" && res.data !== 0)
                    {
                        let outputTemp = " Gerekli ön koşullar:<br/>";
                        res.data.forEach(prq => {
                            outputTemp += prq + "<br/>";
                        });
                        this.setState({errorMessage:this.state.errorMessage+outputTemp});
                    }else{
                        console.error("Gerekli ön koşulların neler olduğunu denetlerken hata!");
                    }
                })
                .catch(() => {
                    console.error("Gerekli ön koşulların neler olduğunu denetlerken ağ hatası!");
                });
            }
            loadedItems++;
            if(loadedItems === loadableItems) this.setState({loading:false});
        })
        .catch(() => {
            //Ön koşullar kontrol edilirken ağ hatası oluştu
            this.setState({error:true});
            console.error("Ön koşulların sağlanıp sağlanmadığını denetlerken ağ hatası!");
        });

        ///////////////////////////////

        data = {token:localStorage.session};
        if(!this.state.error)
        axios.post(server+"course/getLessonList/"+this.props.match.params.name+".php",data)
        .then((res) => {
            if(res.data === 0)
            {
                this.setState({error:true});
                console.error("Ders listesini çekerken hata");
            }else{
                this.setState({courseLessons:res.data});
                loadedItems++;
                if(loadedItems === loadableItems) this.setState({loading:false});
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("Ders listesini çekerken sunucu hatası");
        });

        ///////////////////////////////

        data = {token:localStorage.session};
        if(!this.state.error)
        axios.post(server+"course/getCourseName/"+this.props.match.params.name+"/"+this.props.match.params.sublesson+".php",data)
        .then((res) => {
            if(res.data === 0)
            {
                //hata oluştu
                this.setState({error:true});
                console.error("kurs ve ders isimleri sunucudan çekilirken hata oluştu.");
            }else{
                this.setState({courseName:res.data});
                loadedItems++;
                if(loadedItems === loadableItems) this.setState({loading:false});
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("kurs isimleri çekilirken sunucu hatası oluştu.");
        });

        //////////////////////////////

        data = {token: localStorage.session};
        if(!this.state.error)
        axios.post(server+"course/getLessonContent/"+this.props.match.params.name+"/"+this.props.match.params.sublesson+".php",data)
        .then((res) => {
            if(res.data === 0)
            {
                this.setState({error:true});
                console.error("kurs verisi çekilirken hata oluştu");
            }else{
                if(res.data.quizComplated)
                    this.global.quizComplated = true;
                this.setState({courseContent:res.data});
                loadedItems++;
                if(loadedItems === loadableItems) this.setState({loading:false});
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("kurs verisi çekilirken sunucu hatası");
        });

        ////////////////////////////////

        data = {
            token:localStorage.session,
            course:this.props.match.params.name,
            lesson:this.props.match.params.sublesson,
            answered:false
        };
        if(!this.state.error)
        axios.post(server+"course/getQuestions.php",data)
        .then((res) => {
            if(res.data === 0)
            {
                this.setState({error:true});
                console.error("Kurs soruları çekilirken hata oluştu.");
            }else {
                this.setState({courseQuestions:res.data});
                loadedItems++;
                if(loadedItems === loadableItems) this.setState({loading:false});
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("Kurs soruları çekilirken sunucu hatası");
        });

        ////////////////////////////////

        //::::TODO:::: Daha sonra göster butonu talimatına uy.
        data = {token:localStorage.session, course:this.props.match.params.name};
        if(!this.state.error)
        axios.post(server+"course/showVoteModdel.php",data)
        .then((res) => {
            if(res.data === 1)
            {
                //yorum penceresini aç
                this.setState({showVote:true});
            }
            loadedItems++;
            if(loadedItems === loadableItems) this.setState({loading:false});
        })
        .catch(() => {
            loadedItems++;
            if(loadedItems === loadableItems) this.setState({loading:false});
            console.error("Oylama penceresinin durumu öğrenilirken ağ hatası!");
        });

      }


      componentDidMount()
      {
        this.getData();
        if(localStorage["voted"+this.props.match.params.name+"+"+this.props.match.params.sublesson])
        this.setState({voted:true});
      }

      oncekiKursUrl()
      {
          let url = process.env.PUBLIC_URL+"/ders/"+this.props.match.params.name+"/";
          const currentLesson = this.props.match.params.lessonName;
          const currentSublesson = this.props.match.params.sublesson;
          let indexOfCurrentLesson = 0;
          let indexOfCurrentSubLesson = 0;
          let lesson = this.state.courseLessons.find(({url}) => url === currentLesson);
          let lindex = this.state.courseLessons.findIndex(({url}) => url === currentLesson);
          let i = 0;
          this.state.courseLessons.forEach(element => {
            if(element === lesson)
                indexOfCurrentLesson = i;
            i++;
          });

          let subLesson = lesson.sublessons.find(({url}) => url === currentSublesson);
          i = 0;
          lesson.sublessons.forEach(lesson => {
            if(lesson === subLesson)
                indexOfCurrentSubLesson = i;
            i++;
          })

          if(indexOfCurrentSubLesson === 0)
          {
              //dersin ilk alt dersindeyiz.
              if(indexOfCurrentLesson === 0)
              {
                  //dersin ilk ana dersindeyiz.
                  return "";
              }else{
                  //dersin ilk ana dersinde değiliz.
                  let index = this.state.courseLessons[lindex - 1].sublessons.length - 1;
                  if(this.state.courseLessons[lindex - 1].sublessons[index].type === 1) //bu bir ders
                    url += this.state.courseLessons[lindex - 1].url+"/"+this.state.courseLessons[lindex - 1].sublessons[index].url;
                  else if(this.state.courseLessons[lindex - 1].sublessons[index].type === 2) //bu bir sınav
                    url = process.env.PUBLIC_URL+"/kurslar/quizPlayer/"+this.state.courseLessons[lindex - 1].sublessons[index].url;
                    return url;
              }
          }else{
              //ilk alt derste değiliz.
              if(lesson.sublessons[indexOfCurrentSubLesson-1].type === 1) //bu bir ders
                url+=lesson.url+"/"+lesson.sublessons[indexOfCurrentSubLesson-1].url;
              else if(lesson.sublessons[indexOfCurrentSubLesson-1].type === 2) //bu bir sınav
                url = process.env.PUBLIC_URL+"/kurslar/quizPlayer/"+lesson.sublessons[indexOfCurrentSubLesson-1].url;
            return url;
          }
      }

      sonrakiKursUrl()
      {
          let url = process.env.PUBLIC_URL+"/ders/"+this.props.match.params.name+"/";
          const currentLesson = this.props.match.params.lessonName;
          const currentSublesson = this.props.match.params.sublesson;
          let indexOfCurrentLesson = 0;
          let indexOfCurrentSubLesson = 0;
          let lesson = this.state.courseLessons.find(({url}) => url === currentLesson);
          let lindex = this.state.courseLessons.findIndex(({url}) => url === currentLesson);
          let i = 0;
          this.state.courseLessons.forEach(element => {
            if(element === lesson)
                indexOfCurrentLesson = i;
            i++;
          });

          let subLesson = lesson.sublessons.find(({url}) => url === currentSublesson);
          i = 0;
          lesson.sublessons.forEach(lesson => {
            if(lesson === subLesson)
                indexOfCurrentSubLesson = i;
            i++;
          })

          if(indexOfCurrentSubLesson === lesson.sublessons.length - 1)
          {
              //dersin son alt dersindeyiz.
              if(indexOfCurrentLesson === this.state.courseLessons.length - 1)
              {
                  //dersin son ana dersindeyiz.
                  return "";
              }else{
                  //dersin son ana dersinde değiliz.
                  let index = lindex + 1;
                  if(this.state.courseLessons[index].sublessons[0].type === 1) //bu bir ders
                    url+=this.state.courseLessons[index].url+"/"+this.state.courseLessons[index].sublessons[0].url;
                  else if(this.state.courseLessons[index].sublessons[0].type === 2) //bu bir sınav
                    url=process.env.PUBLIC_URL+"/kurslar/quizPlayer/"+this.state.courseLessons[index].sublessons[0].url;
                  return url;
              }
          }else{
              //son alt derste değiliz.
              if(lesson.sublessons[indexOfCurrentSubLesson+1].type === 1) //bu bir ders
                url+=lesson.url+"/"+lesson.sublessons[indexOfCurrentSubLesson+1].url;
              else if(lesson.sublessons[indexOfCurrentSubLesson+1].type === 2) //bu bir sınav
                url=process.env.PUBLIC_URL+"/kurslar/quizPlayer/"+lesson.sublessons[indexOfCurrentSubLesson+1].url;
              
              return url;
          }
      }




      handleChange(event) {
        this.setState({code: event.target.value});
      }

      vote(up) {
          let data = {
              token:localStorage.session, 
              course:this.props.match.params.name, 
              lesson:this.props.match.params.sublesson,
              pn:""
            };
          if(up)
          {
              data.pn = "p";
          }else{
              data.pn = "n";
          }
          axios.post(server+"course/saveOpinion.php",data);
          localStorage["voted"+this.props.match.params.name+"+"+this.props.match.params.sublesson] = true;
          this.setState({voted: true});
      }

      handleHelp()
      {
          /**
           * Bu fonksiyon; kullanıcı adını, ders adını, konusunu ve mesaj içeriğini veri tabanında saklamalıdır.
           * Bu bağlamda bahsi geçen veriler API arayüzüne yollanacaktır.
           */
        this.setState({show:false});
        let data = {
            token:localStorage.session, 
            course:this.props.match.params.name, 
            lesson:this.props.match.params.sublesson,
            data:this.global.helpData
        };
        axios.post(server+"course/sendHelp.php",data)
        .then((res) => {
            if(res.data === 1)
            {
                this.global.helpData = "";
                this.setState({showToast:true});
            }else{
                this.setState({error:true});
                console.error("Soru gönderilirken beklenmedik hata");
            }
        })
        .catch(() => {
            this.setState({error:true});
            console.error("Soru gönderilirken sunucu hatası");
        });
      }

    voteCallback = (datatype, data) => {
        if(datatype === "star")
            this.global.voteData.star = data;
        else if(datatype === "comment")
            this.global.voteData.comment = data;
        else if(datatype === "homepage")
            this.global.voteData.homepage = data;
        else if(datatype === "name")
            this.global.voteData.name = data;
        
        console.log(this.global.voteData);
      }

      handleButton(button)
      {
          /**
           * 0 => geri (önceki konu)
           * 1 => ileri (sonraki konu)
           * 2 => yardım butonu
           * 3 => yardım penceresini kapatma butonu
           * 4 => yardım penceresi veri gönderme butonu
           * 5 => tost mesajını kapatma butonu
           * 6 => oylama penceresi kapatma butonu
           * 7 => oylama penceresi veri gönderme butonu
           * 8 => yardım yanıtı gönderme butonu
           */
          switch(button)
          {
              case 0:
                  let url = this.oncekiKursUrl();
                  if(url === "")
                    alert("Burası ilk ders, daha önce sayfa bulunamadı.");
                  else
                    history.push(url);
                    window.location.replace(url);
                  break;
              case 1:
                  if(this.global.quizComplated || this.state.courseQuestions.length === 0){
                    let url = this.sonrakiKursUrl();
                    if(url === "")
                      alert("Burası son ders, daha ileride sayfa bulunamadı.");
                    else
                        history.push(url);
                        window.location.replace(url);
                    break;
                  }else{
                    alert("Sonraki derse devam edebilmek için " + 
                    (this.state.courseQuestions.length - this.global.trueAnswers).toString() + 
                    " soruyu doğru yanıtlamanız gerekiyor.");
                    break;
                    
                  }
                  break;
            case 2:
                this.setState({show:true, selectedQuestion: -1});
                this.getAnswerList();
                break;
            case 3:
                this.setState({show:false});
                break;
            case 4:
                this.handleHelp();
                break;
            case 5:
                this.setState({showToast:false});
                break;
            case 6:
                //oylama penceresi kapatma butonu
                window.localStorage.setItem("doNotOpenVote", Math.round((new Date()).getTime() / 1000).toString());
                this.setState({showVote:false});
                break;
            case 7:
                //oylama penceresi veri gönderme butonu
                if(this.global.voteData.star === 0)
                {
                    alert("Lütfen yıldızlara tıklayarak deneyiminizi belirtiniz.");
                }else{
                    let data = {
                        token:window.localStorage.session,
                        course:this.props.match.params.name,
                        data:this.global.voteData
                    };
                    axios.post(server+"course/saveVoteData.php",data)
                    .then((res) => {
                        if(res.data === 1)
                        {
                            alert("Yorumunuz başarıyla kaydedildi. Hizmetlerimizi geliştirebilmemiz için yorumlarınız çok önemli. Zaman ayırdığınız için teşekkür ederiz.");
                            this.setState({showVote:false});
                        }else{
                            alert("Yorumunuz gönderlirken bir altyapı hatası meydana geldi!");
                        }
                    })
                    .catch(() => {
                        alert("Yorumunuz gönderilirken bir ağ hatası meydana geldi!");
                    });
                }
                break;
            case 8:
                //yardım mesajı gönderme butonu
                if(this.global.helpAnswerData == "")
                {
                    console.log(this.sendMessageRef);
                    alert("Lütfen mesajınızı yazınız.");
                    break;
                }
                this.setState({sendLoading:true});
                let data = {token:localStorage.session, qid:this.state.selectedQuestion, data:this.global.helpAnswerData};
                axios.post(server+"course/saveHelpAnswer.php", data)
                .then((res) => {
                    if(res.data === 1)
                    {
                        //mesajları güncelle
                        this.handleShowMessage();
                        this.sendMessageRef.current._valueTracker.setValue("");
                    }else{
                        this.setState({msgError:"Mesajınız gönderilirken bir sorun oluştu!"});
                    }
                    this.setState({sendLoading:false});
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({msgError:e.toString()});
                    this.setState({sendLoading:false});
                });
                break;
              default:
                break;
          }
      }

      handleQuiz(question, answer, item)
      {
        const loading = renderToString(<Spinner animation="border" variant="warning" />);
        const temp = item.target.innerHTML;
        item.target.innerHTML = loading;
        item.target.disabled = true;
        let data = {
            token:localStorage.session,
            course:this.props.match.params.name,
            lesson:this.props.match.params.sublesson,
            answered:true,
            question:question,
            answer:answer
        };
        axios.post(server+"course/getQuestions.php",data)
        .then((res) => {
            if(res.data === 0)
            {
                this.setState({error:true});
                console.error("Soru cevabını kontrol ederken hata oluştu.");
            }else if(res.data === 1){
                this.global.trueAnswers++;
                if(this.global.trueAnswers === this.state.courseQuestions.length)
                {
                    //tüm sorular tamamlandı.
                    //sonucu veri tabanına kaydedelim.
                    let quizScore = {
                        token: localStorage.session,
                        course:this.props.match.params.name,
                        lesson:this.props.match.params.lessonName,
                        sublesson:this.props.match.params.sublesson,
                        wrongTryCount:this.global.wrongTryCount
                    };
                    axios.post(server+"course/saveLessonQuizScore.php", quizScore)
                    .then((res) => {
                        if(res.data === 1)
                        {
                            console.log("Yanıtlar başarıyla kaydedildi.");
                        }else{
                            alert("Yanıtlarınız kaydedilirken bir şeyler ters gitti ve bilinmeyen bir cevap alındı.");
                        }
                    })
                    .catch(() => {
                        alert("Yanıtlarınız kaydedilirken bir ağ hatası oluştu.");
                    });
                    this.global.quizComplated = true;
                }
                item.target.className = "btn btn-success";
                item.target.innerHTML = temp;
            }else if(res.data === 2){
                item.target.className = "btn btn-danger";
                this.global.wrongTryCount++;
                item.target.innerHTML = temp;
            }else{
                item.target.innerHTML = temp;
                this.setState({error:true});
                console.error("Soru cevabı kontrol edilirken bilinmeyen sunucu yanıtı");
            }
            //////////////////DEBUG ONLY////////////////////
            console.log(this.global.wrongTryCount);
        })
        .catch(() => {
            this.setState({error:true});
            console.error("Soru cevabını kontrol ederken sunucu hatası");
        });
      }

      getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }

    /*
    constructor(props)
    {
        super(props);
        xhook.before((request, callback) => {
            // only set header request for the videojs src url (don't touch other xhr requests)
            if (request.url === "https://cdn.turkiyeelektronik.com/REPOSITORY/video/test.mp4") {
              request.xhr.setRequestHeader('X-Custom-PSK', 'mypresharedkey');
            }
            callback();
          });
    }
    */

    updateUnreadCnt()
    {
        let data = {token:localStorage.session};
        axios.post(server+"course/getHelpMessageList.php", data)
        .then((res) => {
            if(res.data.status === 100)
            {
                //işlem başarılı
                let unreadCount = 0;
                res.data.data.forEach((val) => {
                    unreadCount += val.unreadAnswer;
                });
                this.setState({unreadAnswerCnt:unreadCount});
            }else{
                console.error("Mesaj listesi çekilirken hata oluştu. Hata kodu: "+res.data.status.toString()+" Hata mesajı: "+res.data.message);
                this.setState({error:true});
            }
        })
        .catch((e) => {
            console.error(e);
            console.log("Bir hata tespit edildi:");
            console.error(e);
            //this.setState({error:true});
        });
    }
    getAnswerList()
    {
        this.setState({answersLoading:true});
        let data = {token:localStorage.session};
        axios.post(server+"course/getHelpMessageList.php", data)
        .then((res) => {
            if(res.data.status === 100)
            {
                //işlem başarılı
                console.log(res.data);
                let unreadCount = 0;
                res.data.data.forEach((val) => {
                    unreadCount += val.unreadAnswer;
                });
                this.setState({answerList:res.data.data, answersLoading:false, selectedQuestion: -1, unreadAnswerCnt:unreadCount});
                console.log("Okunmamış mesaj sayısı: "+unreadCount.toString());
            }else{
                console.error("Mesaj listesi çekilirken hata oluştu. Hata kodu: "+res.data.status.toString()+" Hata mesajı: "+res.data.message);
                this.setState({error:true});
            }
        })
        .catch((e) => {
            console.error(e);
            this.setState({error:true});
        });
    }

    handleShowMessage(option = null)
    {
        this.setState({msgLoading: true});
        let data = {};
        if(option != null)
        {
            this.setState({selectedQuestion:option.target.value, msgLoading:(!option.target.value == -1)});
            if(option.target.value == -1)
                return;
            data = {token:localStorage.session, qid:option.target.value};
        }else{
            data = {token:localStorage.session, qid:this.state.selectedQuestion}
        }
        
        
        axios.post(server+"course/getHelpMessage.php", data)
        .then((res) => {
            if(res.data.status === 100)
            {
                //işlem başarılı
                this.setState({msgLoading: false, messageList:res.data.data, msgError:""});
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });
                this.updateUnreadCnt();
                console.log(res.data);
            }else{
                //bir hata oluştu
                console.error("Mesaj verileri çekilirken hata!");
                console.log(res.data);
                this.setState({msgLoading: false, msgError:res.data.message});
            }
        })
        .catch((e) =>
        {
            this.setState({msgLoading: false, msgError:e.toString()});
            console.error(e);
        });
    }



    render(){
        /*
        const config = {
            src: this.state.courseContent.video,
            type: 'video/mp4',
            controls:true,
            autoplay: false
        }*/
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              Bir noktayı anlamadıysanız veya herhangi bir sorunuz varsa sorunuzu bu buton aracılığıyla ekibimize iletebilirsiniz.
              Ekibimiz mümkün olan en kısa sürede size dönüş yapacaktır.
            </Tooltip>
        );
        const show = this.state.show;
        const showVote = this.state.showVote;
        if(this.state.error)
            if(this.state.errorMessage === "")
                return(<ErrorPage/>);
            else
                return(<Alert variant="danger"><JsxParser jsx={this.state.errorMessage} /></Alert>);
        else if(this.state.loading)
            return(<Loading/>   );
        else 
            return(
            <>
            <Navbar/>
            <div className="container" style={{marginBottom:"150px"}}>
            <div>
                <div className="kursBaslik"><span className="kursAdi">{this.state.courseName.name}</span> - <span className="konuAdi">{this.state.courseName.sublesson}</span><hr/></div>

                <div className="container">
                    <div className="row coursePlayerContainer">
                        <div className="col-12 video">
                        <div style={{position:"relative", paddingTop:"56.25%"}}>
                            <iframe 
                                src={"https://iframe.mediadelivery.net/embed/17160/"+this.state.courseContent.video+"?autoplay=false&preload=false&token="+this.state.courseContent.videoToken+"&expires="+this.state.courseContent.videoExpires}
                                loading="lazy" 
                                style={{border:"none", position:"absolute", "top":"0", height:"100%", width:"100%"}}
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
                                allowfullscreen="true">
                            </iframe>
                        </div>
                        </div>
                    </div>
                </div>

                <h2 style={{textDecoration:"underline", paddingTop:"30px"}}>Yazılı Anlatım</h2>
                <JsxParser jsx={this.state.courseContent.text} />


                <h2 style={{textDecoration:"underline", paddingTop:"30px"}}>Sorular</h2>
                <small style={this.global.quizComplated ? {color:"green"} : {color:"white"}}>{this.global.quizComplated ? "Bu sınavı daha önce tamamladınız." : "Bu sınava daha önce girmediniz." }</small>
                <Questions 
                    courseQuestions={this.state.courseQuestions}
                    global={this.global}
                    handleQuiz={this.handleQuiz}
                    getRandomInt={this.getRandomInt}
                />

                <div className="courseSurvey col-md-12">
                    {this.state.voted ? null : <i className="pageVote bi bi-hand-thumbs-up-fill" onClick={() => this.vote(1)}></i>}
                    {this.state.voted ? <span>Değerlendirmeniz için teşekkür ederiz.</span> : <span style={{display:"inline-block"}}>İçeriğimizi nasıl buldunuz?</span>}
                    {this.state.voted ? null : <i className="pageVote bi bi-hand-thumbs-down-fill right" onClick={() => this.vote(0)}></i>}
                </div>

                <div className="courseDownNavigation">
                    <Button variant="primary" size="lg" onClick={() => this.handleButton(0)}>Geri</Button>
                    <Button variant="primary" size="lg" onClick={() => this.handleButton(1)} className="right">İleri</Button>
                </div>
            </div>
            </div>
            <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <div className="soruSor" onClick={() => this.handleButton(2)}>
                    Soru Sormak İstiyorum {this.state.unreadAnswerCnt != 0 ? <Badge variant="light">{this.state.unreadAnswerCnt.toString()}</Badge> : ""}
                </div>
            </OverlayTrigger>
            <Footer/>


            <Modal className="soruModal user-select-none" show={show} onHide={() => this.handleButton(3)}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.tabKey == "send" ? "Bir sorunuz mu var?" : "Yanıtlara Bakın"}</Modal.Title>
        </Modal.Header>
        <Tabs 
            activeKey={this.state.tabKey}
            onSelect={(k) => {this.setState({tabKey:k}); if(k === "read") this.getAnswerList();}}
            className="mb-3"
        >
        <Tab eventKey="send" title="Yeni Soru">
        <Modal.Body>Ekibimiz sorularınızı cevaplamak için sizi bekliyor. <br/>
        Ayrıca sorduğunuz sorular içeriklerimizin geliştirilmesinde bize yardımcı oluyor. <a href="#bilgi">Daha fazla bilgi al</a><br/>
        Lütfen sorunuzu aşağıdaki alana yazınız:<br/>
        <CKEditor
            editor={ ClassicEditor }
            data={this.global.helpData}
            config={{placeholder:"Sorunuzu buraya yazabilirsiniz..."}}
            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                console.log( 'Editor is ready to use!', editor );
            }}
            onChange={ ( event, editor ) => {
            this.global.helpData = editor.getData();
            }}
        />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleButton(3)}>
            Kapat
          </Button>
          <Button variant="primary" onClick={() => this.handleButton(4)}>
            Gönder
          </Button>
        </Modal.Footer>
        </Tab>
        <Tab eventKey="read" title="Cevapları Oku">
            <Modal.Body>
                {this.state.answersLoading ? 
                <>
                    <div style={{minHeight:"150px", width:"100%", backgroundColor:"#002b42", borderRadius:"20px", color:"white"}}>
                        <Loading/> 
                    </div>
                </> : <>

                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Soru Seçiniz</Form.Label>
                    <Form.Control as="select" onChange={(e) => this.handleShowMessage(e)}>
                        <option value={-1}>- Lütfen Seçiniz -</option>
                        {
                            this.state.answerList.map((val, i) => {
                                return(
                                    <option value={val.qid} key={"option-"+i.toString()}>
                                        {
                                            val.unreadAnswer === 0 ? val.name : val.name + " (" + val.unreadAnswer.toString() + " okunmamış)"
                                        }
                                    </option>
                                )
                            })
                        }
                    </Form.Control>
                </Form.Group>

                {
                    this.state.msgError != "" ?
                    <>
                        <Alert variant='danger'>{this.state.msgError}</Alert>
                    </>:
                    this.state.answerList.length == 0 ?
                    <>
                        <Alert variant='warning'>Daha önce hiç soru sormamışsınız.</Alert>
                    </>
                    : this.state.selectedQuestion == -1 ?
                    <>
                        <Alert variant='warning'>Lütfen yukarıdaki menüden bir soru seçiniz.</Alert>
                    </>
                    : this.state.msgLoading ?
                    <>
                        <div style={{minHeight:"150px", padding:"30px", width:"100%", backgroundColor:"#002b42", borderRadius:"20px", color:"white"}}>
                            <center>
                                <Spinner style={{width:"64px", height:"64px"}} animation="border" variant="info" />
                                <h1>Yükleniyor...</h1>
                            </center>
                        </div>
                    </>
                    :
                    <>
                    <div className="messageArea" >
                        {
                            this.state.messageList.map((val, i) => {
                                return(
                                    <>
                                    <div className={val.qa == 0 ? "recivedMessage" : "sendedMessage"} key={"message"+val.mid.toString()}>
                                        <JsxParser jsx={val.data}/>
                                    </div>
                                    </>
                                )
                            })
                        }
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                </div>
                <InputGroup className="mb-3">
                    <FormControl ref={this.sendMessageRef} placeholder="Yanıtınız..." onChange={(e) => this.global.helpAnswerData = e.target.value} />
                    <InputGroup.Append>
                        <Button variant="outline-secondary" onClick={() => this.handleButton(8)} disabled={this.state.sendLoading}>
                            {
                                this.state.sendLoading ? <Spinner animation="grow" variant="primary" /> : <i className="bi bi-send"></i>
                            }
                            
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
                    </>
    
                }
                
                </>
                }
                

            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleButton(3)}>
            Kapat
          </Button>
        </Modal.Footer>
        </Tab>
        </Tabs>
      </Modal>

      <Modal className="soruModal user-select-none" show={showVote} onHide={() => this.handleButton(6)}>
        <Modal.Header closeButton>
          <Modal.Title>Lütfen bizi değerlendirin.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Vote callback={this.voteCallback} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleButton(6)}>
            Daha Sonra
          </Button>
          <Button variant="primary" onClick={() => this.handleButton(7)}>
            Yorumumu Gönder
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="soruModal user-select-none" show={this.state.showAnswerModel} onHide={() => this.handleButton(8)} size="xl" dialogClassName="mesajModal">
        <Modal.Header closeButton>
          <Modal.Title>Sorduğunuz Sorular</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Alert variant="primary">Daha önce hiç soru sormamışsınız.</Alert>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row>
    <Col sm={3}>
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="first">Tab 1</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">Tab 2</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
            <div className="messageArea">
                <div className="sendedMessage">Bu bi deneme soru şurayı anlamadım.</div>
                <div className="recivedMessage">Merhaba bu bi deneme cevap.</div>
                <div className="sendedMessage">Peki tamam ama</div>
                <div className="sendedMessage">Lorem dolor sid amed</div>
                <div className="recivedMessage">Bla bla blaa...</div>
                <div className="recivedMessage">Bla bllaaaaa bllaaaaaa.</div>
                <div className="sendedMessage">Lorem dolor sid amed</div>
                <div className="recivedMessage">Bla bla blaa...</div>
                <div className="recivedMessage">Bla bllaaaaa bllaaaaaa.</div>
                <div className="sendedMessage">Lorem dolor sid amed</div>
                <div className="recivedMessage">Bla bla blaa...</div>
                <div className="recivedMessage">Bla bllaaaaa bllaaaaaa.</div>
            </div>
          <InputGroup className="mb-3">
            <FormControl placeholder="Yanıtınız..." />
            <InputGroup.Append>
            <Button variant="outline-secondary"> <i className="bi bi-send"></i></Button>
            </InputGroup.Append>
        </InputGroup>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <p>dneememee</p>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleButton(8)}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast
      show={this.state.showToast}
      onClick={() => this.handleButton(5)}
      style={{
        position: 'fixed',
        top: 60,
        right: 0,
        color: 'black'
      }}>
  <Toast.Header>
    <strong className="mr-auto">Bilgi</strong>
    <small>Sorunuz Hakkında</small>
  </Toast.Header>
  <Toast.Body>Sorunuz ekibimize başarıyla iletildi.</Toast.Body>
</Toast>

<Toast
    show={this.state.showNotification}
    onClick={() => this.setState({showNotification:false})}
    style={{
        position: 'fixed',
        top: 60,
        right: 0,
        color: 'black'
      }}
>
    <Toast.Header>
        <strong className="mr-auto">Bildirim</strong>
        <small>{this.state.notificationHeader}</small>
    </Toast.Header>
    <Toast.Body>{this.state.notificationBody}</Toast.Body>
</Toast>
            </>
        )
    }
}

export default CoursePlayer;